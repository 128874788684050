import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { LineV2 } from '../../../models/classes/line-v2';
import { TrendV2 } from '../../../models/classes/trend-v2';
import { HistorianService } from '../../../services/historian.service';
import { DatepickerService } from 'src/app/smartfiltration/services/datepicker.service';

@Component({
  selector: 'app-trend-v2',
  templateUrl: './trend-v2.component.html',
  styleUrls: ['./trend-v2.component.scss']
})
export class TrendV2Component implements OnInit, OnChanges {


  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  public lineChartData: ChartConfiguration['data'] = { datasets: [], labels: [] };

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    resizeDelay: 100,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          callback: function (value: any, index: any, labels) {
            const date = new Date(this.getLabelForValue(value));
            return [`  ${date.toLocaleTimeString()}  `, `  ${date.toLocaleDateString()}  `]
          }
        }
      },
      y: {
        beginAtZero: true,
      }
    },
    plugins: {
      decimation: {
        enabled: false,
        algorithm: 'min-max',
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxHeight: 2,
          boxWidth: 10
        }
      },
      tooltip: {
        mode: 'index',
        callbacks: {
          title: function (item: any) {
            const datepicker = localStorage.getItem("datepicker");
            const date = datepicker ? JSON.parse(datepicker) : null;

            return new Date(Number(item[0].label)).toLocaleString(undefined, { timeZone: date.timeZone });
          },
        }
      },
      zoom: {
        pan: {
          enabled: true,
          modifierKey: 'alt'
        },
        zoom: {
          drag: {
            enabled: true,
            threshold: 10,
          },
          mode: 'xy',
        },
        limits: {
          y: { min: 'original', max: 'original' }
        }
      },
      datalabels: {
        display: false
      }
    },
  };

  @Input() data?: TrendV2;

  public legend: Record<string, string> = {}
  public contentReady: boolean = false;

  constructor(private service: HistorianService) { }

  ngOnInit(): void {
    this.data?.lines.forEach(line => {
      this.legend[line.name] = line.color;
    });
  }

  ngOnChanges(): void {
    if (!this.data) return;

    this.setupLines();
    this.fillChart();
  }

  public resetZoom(event: MouseEvent) {
    this.chart?.chart?.resetZoom();
  }

  private set: Set<string> = new Set<string>();

  // To ensure that line's data have the same size as the label's array,
  //collect all de data before integrate in the chart and fill whitespaces with NaN values.
  private fillChart(): void {
    const lines = this.data!.lines

    if (!this.data || !lines.length) return;

    (this.data.response) ? this.batch(this.data.response) : this.service.getValues(this.data?.request).subscribe(res => this.batch(res));
  }

  private setupLines(): void {
    this.lineChartData.datasets = [];

    this.data!.lines.forEach((line: LineV2) => {
      (line.position != 'none') ? this.diferenceLine(line) : this.packLines(line);
    });
  }

  private batch(res: Record<string, any[]>) {
    const fields = this.data!.lines.filter(x => typeof x.field === 'string');
    const keys = fields.length > 0 ? this.data?.lines.map(x => x.field) : this.data?.request?.field.split(',');

    let values: Map<string, number>[] = [];
    //Filter data which comes from backend
    keys?.forEach(key => {
      const uniq = new Map<string, number>();
      res[key]?.forEach(element => {
        this.set.add(element.time);
        uniq.set(element.time, element.value);
      })
      values.push(uniq);
    });

    const set = [...this.set].sort();

    //Fill structures with data filtered
    for (let i = 0; i < values.length; i++) {
      const element = values[i];
      let line = this.lineChartData.datasets[i]?.data;

      if (!line) continue;
      set.forEach(d => line.push(element.get(d) || line.at(-1) || NaN))
    }

    // //Fill labels and update the chart, at the end clear the structures for future uses
    var arr: any[] = [];
    Array.from(set).forEach((x: any) => {
      arr.push(new Date(x).getTime())
    });

    this.lineChartData.labels = arr;
    this.chart?.render();
    this.set.clear();
    this.contentReady = true;
  }

  private diferenceLine(line: LineV2) {
    let color = line.color;
    let primary = color;
    let secondary = color;
    let meassure = this.data!.meassure;

    this.lineChartData.datasets.push({
      data: [],
      label: line.name,
      yAxisID: line.id.toString(),
      backgroundColor: secondary,
      borderColor: primary,
      radius: 0,
      pointHoverRadius: 0,
      pointBackgroundColor: secondary,
      pointBorderColor: primary,
      spanGaps: true,
      animation: { duration: 0 },
      stepped: line.type,
    });

    try {
      this.lineChartOptions!.scales![line.id.toString()] = {
        position: line.position,
        grid: {
          color: secondary,
        },
        ticks: {
          color: primary
        },
        min: line.min,
        max: line.max
      }

      this.lineChartOptions!.plugins!.tooltip!.callbacks!.label = function (item: any) {
        return item.formattedValue + ' ' + meassure;
      }
    } catch (error) {
      console.error('Unable to set meassure', error)
    }

  }

  private packLines(line: LineV2) {
    let color = line.color;
    let primary = color;
    let secondary = color;
    let meassure = this.data!.meassure;

    this.lineChartData.datasets.push({
      data: [],
      label: line.name,
      backgroundColor: secondary,
      borderColor: primary,
      radius: 0,
      pointHoverRadius: 0,
      pointBackgroundColor: secondary,
      pointBorderColor: primary,
      spanGaps: true,
      animation: { duration: 0 },
      stepped: line.type
    });

    try {
      this.lineChartOptions!.scales!['y']!.ticks =
      {
        // Include a meassure sign in the ticks
        callback: function (value: any) {
          const max = value > 99 ? 0 : 2;
          return parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: max }) + '  ' + meassure;
        }
      }

      this.lineChartOptions!.plugins!.tooltip!.callbacks!.label = function (item: any) {
        const max = item.raw > 99 ? 0 : 2;
        const value = parseFloat(item.raw).toLocaleString(undefined, { maximumFractionDigits: max })
        return value + ' ' + meassure;
      }
    } catch (error) {
      console.error('Unable to set meassure', error)
    }

  }

}
