import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnDefinition } from '@gea/digital-ui-lib';

import * as moment from 'moment';

import { Digital } from '../../models/classes/digital';
import { TrendV2 } from '../../models/classes/trend-v2';
import { Step } from '../../models/interfaces/step';

import { HistorianService } from '../../services/historian.service';
import { MachineType } from '../../models/interfaces/machine-type';
import { ShowTimePopupComponent } from '../../components/others/show-time-popup/show-time-popup.component';
import { MatDialog } from '@angular/material/dialog';

import { constants } from 'src/environments/constants';
import { zip } from 'rxjs';
import { Gantt } from '../../models/classes/gantt';
import { DatepickerService } from '../../services/datepicker.service';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {

  public data: Record<string, any> = {};
  public date = { timestart: '', timeend: '', duration: 0 };

  public processGantt: ColumnDefinition[] = [];

  public contentReady: boolean = true;

  private machineId: number = 0;

  constructor(private service: HistorianService, private active: ActivatedRoute, private dialog: MatDialog, private dateService: DatepickerService) { }

  ngOnInit(): void {
    zip(this.active.queryParams, this.active.params).subscribe(([query, params]) => {
      this.machineId = parseInt(params['id']);

      const duration = new Date(query['timeend']).getTime() - new Date(query['timestart']).getTime();
      this.date = { timestart: query['timestart'], timeend: query['timeend'], duration: duration };
      this.dialog.open(ShowTimePopupComponent, { data: { date: this.date, process: constants.stepTypes.production } });

      this.setColumns();

      this.service.getMachineType(this.machineId).subscribe(m => {
        this.fillData(this.date.timestart, this.date.timeend, m);
        this.table(this.date.timestart, this.date.timeend, m);
      });
    });
  }

  private setColumns(): void {
    const width = 210;

    this.processGantt = [
      { displayName: "Step Name", key: "step" },
      { displayName: "Start Time", key: "start" },
      { displayName: "Total Time", key: "duration" },
    ];
  }

  private fillData(start: string, end: string, machine: MachineType): void {
    this.data['feed'] = new Digital({ id: 1, title: 'Feed', meassure: machine.totalisedvolume_eu_name, request: this.service.fillRequest('UP01_Prod_Amount_Counter_AV', start, end), maxValue: true });
    this.data['total_permeate'] = new Digital({ id: 2, title: 'Total Permeate', meassure: machine.totalisedvolume_eu_name, request: this.service.fillRequest('UP01_Perm_Amount_Counter_AV', start, end), maxValue: true });
    this.data['retentate'] = new Digital({ id: 3, title: 'Retentate', meassure: machine.totalisedvolume_eu_name, request: this.service.fillRequest('UP01_Ret_Amount_Counter_AV', start, end), maxValue: true });
    this.data['electric_power'] = new Digital({ id: 4, title: 'Total Power', meassure: machine.power_eu_name, request: this.service.fillRequest('DC_PowerProd_Total_Act_Power', start, end), maxValue: true });
    this.data['peak_power_consumption'] = new Digital({ id: 4, title: 'Peak Power Consumption', meassure: machine.power_eu_name, request: this.service.fillRequest('DC_PowerProd_Total_ActualPower', start, end), maxValue: true });
    this.data['c02_emission'] = new Digital({ id: 5, title: 'CO2 Emission', meassure: machine.co2_eu_name, adjust: .0002896, request: this.service.fillRequest('DC_PowerProd_Total_Act_Power', start, end), altBtn: true, decimals: 2, maxValue: true });

    const skid = ['D04PT01', 'F01PT01', 'F03PT01', 'P01PT01', 'P01PT02'].toString();
    this.data['skid'] = new TrendV2({
      id: 8, title: "", meassure: machine.pressure_eu_name, request: this.service.fillRequest(skid, start, end), lines: [
        { id: 1, name: 'D04PT01', color: '#488f95', position: 'none' },
        { id: 2, name: 'F01PT01', color: '#3e464a', position: 'none' },
        { id: 3, name: 'F03PT01', color: '#8a963c', position: 'none' },
        { id: 4, name: 'P01PT01', color: '#ba2f29', position: 'none' },
        { id: 5, name: 'P01PT02', color: '#326fb0', position: 'none' },
      ]
    });

    //Fill Trends
    const matrix = [];

    for (let x = 1; x <= machine.loopcount; x++) {
      matrix[x - 1] = [`L0${x}TT01`, `L0${x}FT01`, `L0${x}PT01`, `L0${x}CT01`]
    }

    const temperatures = matrix.map(e => e[0]);
    this.data['temperature'] = new TrendV2({
      id: 8,
      title: "Loop Temperature",
      meassure: machine.temperature_eu_name,
      request: this.service.fillRequest(temperatures.toString(), start, end),
      lines: temperatures.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const flows = matrix.map(e => e[1]);
    this.data['flow'] = new TrendV2({
      id: 9,
      title: "Loop Flow",
      meassure: machine.flow_eu_name,
      request: this.service.fillRequest(flows.toString(), start, end),
      lines: flows.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const pressures = matrix.map(e => e[2]);
    this.data['pressure'] = new TrendV2({
      id: 7,
      title: "Loop Pressure",
      meassure: machine.pressure_eu_name,
      request: this.service.fillRequest(pressures.toString(), start, end),
      lines: pressures.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const conds = matrix.map(e => e[3]);
    this.data['conductivity'] = new TrendV2({
      id: 11,
      title: "Loop Conductivity",
      meassure: machine.conductivity_eu_name,
      request: this.service.fillRequest(conds.toString(), start, end),
      lines: conds.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });
  }

  private table(start: string, end: string, machine: MachineType): void {
    const { timeZone } = this.dateService.getRange();

    this.service.getSteps(this.service.fillRequest('UP01_Master_Sequence', start, end)).subscribe(res => {
      let steps: Record<number, Step> = {};
      machine.steps.forEach((s: Step) => steps[s.order] = s);
      let table = res.map(x => ({ step: steps[x.step].name, start: new Date(x.timestart).toLocaleString(undefined, { timeZone: timeZone }), duration: x.durationseconds }));

      if (table[0] && table[0].duration <= 0) table.shift();
      if (table[table.length - 1] && table[table.length - 1].duration <= 0) table.pop();

      this.data['master'] = new Gantt({ id: 1, response: res, machine: machine });
      this.data['tableMaster'] = table.map(x => ({ step: x.step, start: x.start, duration: this.getDuration(x.duration) }));

      this.contentReady = false;
    })
  }

  private getDuration(value: number) {
    let temp = moment.utc(value * 1000);
    let duration = Number(temp) > 86400000 ? `${Math.floor(moment.duration(value).asDays())}d ` : ''
    duration += Number(temp) > 3600000 ? temp.format('HH[h] mm[m] ss[s]') : temp.format('mm[m] ss[s]');
    return duration;
  }

}
