import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { HistorianService } from '../../../services/historian.service';
import { Chart } from '../../../models/classes/chart';
import { Step } from '../../../models/interfaces/step';
import { Context } from 'chartjs-plugin-datalabels';
import { DatepickerService } from 'src/app/smartfiltration/services/datepicker.service';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit, OnChanges {

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  @Input() data?: Chart;

  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
      tooltip: {
        callbacks: {
          label: function (item: any) {
            return item.raw.toLocaleString(undefined, { maximumFractionDigits: 2 }) + " %";
          }
        }
      },
      datalabels: {
        display: (context: Context) => {
          const dataset = context.dataset.data.map(e => Number(e));
          let aux = 0;

          for (let i = 0; i < dataset.length; i++)
            if (context.chart.getDataVisibility(i)) aux += dataset[i];

          return (aux * .20) < dataset[context.dataIndex];
        },
        color: 'white',
        formatter: (value, context) => {
          return Number(value).toLocaleString(undefined, { maximumFractionDigits: 2 }) + " %";
        },
      }
    }
  };

  public pieChartData: ChartConfiguration['data'] = {
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: ['#0303b8', '#1aff80', '#1f9dff', '#ff80ff'],
      hoverBackgroundColor: ['#0303b8', '#1aff80', '#1f9dff', '#ff80ff'],
      hoverBorderColor: ['#0303b8', '#1aff80', '#1f9dff', '#ff80ff']
    }]
  };

  public start: string = '';
  public end: string = ''
  public contentReady: boolean = false;

  constructor(private service: HistorianService, private dateService: DatepickerService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.data || !this.data.request) return;
    const { timeZone } = this.dateService.getRange();

    this.start = new Date(this.data.request.starttime).toLocaleString(undefined, { timeZone: timeZone });
    this.end = new Date(this.data.request.endtime).toLocaleString(undefined, { timeZone: timeZone });
    this.loadData();
  }

  ngOnInit(): void { }

  private loadData(): void {
    if (!this.data || !this.data?.machine || !this.data?.machine.steps) return;

    let steps: Map<number, Step> = new Map<number, Step>();
    let status: Map<number, string> = new Map<number, string>();
    let record: Map<number, number> = new Map<number, number>();

    const sorted = this.data?.machine.steps.sort((x: Step, y: Step) => x.status_name.localeCompare(y.status_name));
    sorted.forEach((s: Step) => {
      if (!steps.has(s.order)) steps.set(s.order, s)
      if (!record.has(s.status_id)) record.set(s.status_id, 0);
      if (!status.has(s.status_id)) status.set(s.status_id, s.status_name);
    });

    this.service.getSteps(this.data.request).subscribe(res => {
      let total = 0;

      let labels: any = [];
      let dataset: any = [];

      res.forEach(e => {
        total += e.durationseconds;

        if (steps.has(e.step)) {
          const id = steps.get(e.step)!.status_id;
          record.set(id, record.get(id)! + e.durationseconds)
        }
      });
      record.forEach((val: number, key: number) => {
        labels.push(status.get(key));
        dataset.push((val / total) * 100);
      })

      this.pieChartData.labels = labels;
      this.pieChartData.datasets[0].data = dataset;

      this.chart?.update();
      this.contentReady = true;
    })
  }

}