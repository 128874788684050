import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ChemicalComponent } from './smartfiltration/containers/chemical/chemical.component';
import { CipComponent } from './smartfiltration/containers/cip/cip.component';
import { ConsumptionComponent } from './smartfiltration/containers/consumption/consumption.component';
import { FluxTestComponent } from './smartfiltration/containers/flux-test/flux-test.component';
import { ListOfCipComponent } from './smartfiltration/containers/list-of-cip/list-of-cip.component';
import { ListOfProductionComponent } from './smartfiltration/containers/list-of-production/list-of-production.component';
import { OverviewComponent } from './smartfiltration/containers/overview/overview.component';
import { ProcessComponent } from './smartfiltration/containers/process/process.component';
import { ServiceComponent } from './smartfiltration/containers/service/service.component';
import { TreeViewComponent } from './smartfiltration/containers/tree-view/tree-view.component';

const routes: Routes = [
  {
    path: 'error',
    redirectTo: '',
    canActivate: [MsalGuard],
  },
  {
    path: 'state',
    redirectTo: '',
    canActivate: [MsalGuard],
  },
  {
    path: 'code',
    redirectTo: '',
    canActivate: [MsalGuard],
  },
  {
    path: 'smartfiltration',
    canActivate: [MsalGuard],
    children: [
      { path: '', component: TreeViewComponent, title: "IPF - Machines" },
      { path: ':id/overview', component: OverviewComponent, title: "IPF - Overview" },
      { path: ':id/cip-list', component: ListOfCipComponent, title: "IPF - CIP" },
      { path: ':id/production-list', component: ListOfProductionComponent, title: "IPF - Proudction" },
      { path: ':id/cip-details', component: CipComponent, title: "IPF - CIP" },
      { path: ':id/process-details', component: ProcessComponent, title: "IPF - Process" },
      { path: ':id/consumption', component: ConsumptionComponent, title: "IPF - Consumption" },
      { path: ':id/flux-test', component: FluxTestComponent, title: "IPF - Flux test" },
      { path: ':id/chemical', component: ChemicalComponent, title: "IPF - Chemical" },
      { path: ':id/service', component: ServiceComponent, title: "IPF - Service" },
    ]
  },
  {
    path: '**',
    redirectTo: 'smartfiltration'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
