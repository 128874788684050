<div class="overlay" cdkDrag cdkDragRootElement=".cdk-overlay-pane" [style.width.vw]="width" [style.height.vh]="height">
    <div class="content">
        <div class="control" (dblclick)="changeSize()" cdkDragHandle>
            <div class="title">
                <span>{{title}}</span>
            </div>
            <button mat-button class="btn" (click)="close()">
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
            </button>
        </div>
        <div class="trend">
            <app-trend-v2 [data]="trend"></app-trend-v2>
        </div>
    </div>
</div>