import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';

import * as moment from 'moment';
import { DatepickerService } from '../../../services/datepicker.service';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [{ provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy }]
})
export class DatepickerComponent implements OnInit {

  @Output() rangeChange: EventEmitter<any> = new EventEmitter<any>();

  public calendar: any;
  public start?: any;

  public dateForm = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    checkbox: new FormControl()
  });

  public tab: number = 0;

  public selectedRange: number = 0;
  public selectedTimeZone: number = 0;

  constructor(private datepicker: DatepickerService) { }

  ngOnInit(): void {
    const datepicker = this.datepicker.getRange();

    const start = moment(datepicker.start);
    const end = moment(datepicker.end)

    this.calendar = new DateRange(start, end);
    this.selectedTimeZone = datepicker.selectedTimeZone;
    this.selectedRange = datepicker.selectedRange;

    this.fillForm(start, end);
    this.handleCalendar();
  }

  public submit(): void {
    this.datepicker.setRange(this.calendar.start.toISOString(), this.calendar.end.toISOString(), this.selectedRange, this.selectedTimeZone);
    this.rangeChange.emit(true);
  }

  public close(): void {
    this.rangeChange.emit(false);
  }

  public dateChange(date: any): void {
    if (!this.start) {
      this.start = date;
      this.calendar = new DateRange(date, null);
    } else {
      this.calendar = (this.start < date) ? new DateRange(this.start, date) : new DateRange(date, this.start);
      this.start = null;
    }

    this.fillForm(this.calendar.start, this.calendar.end)
  }

  public getTimeRangeName(): string {
    switch (this.selectedRange) {
      case 1: return "DATEPICKER.LAST_60";
      case 2: return "DATEPICKER.TODAY";

      case 3: return "DATEPICKER.LAST_24";
      case 4: return "DATEPICKER.YESTERDAY";

      case 5: return "DATEPICKER.LAST_7";
      case 6: return "DATEPICKER.LAST_WEEK";

      case 7: return "DATEPICKER.LAST_30";
      case 8: return "DATEPICKER.LAST_MONTH";

      case 9: return "DATEPICKER.LAST_90";
      case 10: return "DATEPICKER.LAST_QUARTER";

      default: return "DATEPICKER.CUSTOM";
    }
  }

  public setTimeRange(selectedRange: number): void {
    this.selectedRange = selectedRange;

    let yesterday = moment();
    let today = moment();

    switch (selectedRange) {
      case 1: //Last 60 minutes
        yesterday = yesterday.subtract(60, 'minutes');
        break;

      case 2: //Today
        yesterday = yesterday.startOf('day');
        today = today.endOf("day");
        break;

      case 3: //Last 24 hours
        yesterday = yesterday.subtract(24, 'hours');
        break;

      case 4: //Yesterday
        yesterday = yesterday.subtract(1, 'day').startOf('day');
        today = today.subtract(1, 'day').endOf('day');
        break;

      case 5: //Last 7 Days
        yesterday = yesterday.subtract(7, 'days');
        break;

      case 6: //Last Week
        yesterday = yesterday.subtract(1, 'week').startOf('week');
        today = today.subtract(1, 'week').endOf('week');
        break;

      case 7: //Last 30 Days
        yesterday = yesterday.subtract(30, 'days');
        break;

      case 8: //Last Month
        yesterday = yesterday.subtract(1, 'month').startOf('month');
        today = today.subtract(1, 'month').endOf('month');
        break;

      case 9: //Last 90 Days
        yesterday = yesterday.subtract(90, 'days');
        break;

      case 10: //Last Quarter
        yesterday = yesterday.subtract(3, 'month').startOf('month');
        today = today.subtract(1, 'month').endOf('month');
        break;
    }

    this.fillForm(yesterday, today)
  }

  public setTimeZone(timeZone: number) {
    this.selectedTimeZone = timeZone;
  }

  public getTimeZoneName(): string {
    if (this.selectedTimeZone == 1) return "DATEPICKER.UTC"
    return "DATEPICKER.LOCAL"
  }

  private fillForm(start: moment.Moment, end: moment.Moment | null): void {
    this.dateForm.controls['start'].setValue(start.format(moment.HTML5_FMT.DATETIME_LOCAL));
    this.dateForm.controls['end'].setValue(end?.format(moment.HTML5_FMT.DATETIME_LOCAL));
  }

  private handleCalendar(): void {
    this.dateForm.controls['start'].valueChanges.subscribe(res => {
      let temp = new Date(res);
      if (!this.calendar || !this.calendar.end || isNaN(temp.getTime())) return;
      let start = moment(temp.toISOString());
      let end = this.calendar.end;

      this.calendar = new DateRange(start, end)
    });

    this.dateForm.controls['end'].valueChanges.subscribe(res => {
      let temp = new Date(res);
      if (!this.calendar || !this.calendar.start || isNaN(temp.getTime())) return;
      let start = this.calendar.start;
      let end = moment(temp.toISOString());

      this.calendar = new DateRange(start, end);
    });

    this.dateForm.controls['checkbox'].valueChanges.subscribe(res => {
      if (res) {
        this.dateForm.controls['start'].disable();
        this.dateForm.controls['end'].disable();
      }
      else {
        this.dateForm.controls['start'].enable();
        this.dateForm.controls['end'].enable();
      }
    })
  }

}
