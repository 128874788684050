import { MachineType } from "../interfaces/machine-type";
import { Request } from "./request";

export class Chart {

    private _id: number;
    private _request: Request;
    private _response: any;
    private _machine: MachineType;

    constructor(obj?: any) {
        this._id = obj.id;
        this._request = obj.request;
        this._response = obj.response;
        this._machine = obj.machine;
    }

    public get id(): number {
        return this._id;
    }

    public set id(v: number) {
        this._id = v;
    }

    public get request(): Request {
        return this._request;
    }

    public set request(v: Request) {
        this._request = v;
    }

    public get response(): any {
        return this._response;
    }
    public set response(v: any) {
        this._response = v;
    }

    public get machine(): MachineType {
        return this._machine;
    }
    public set machine(v: MachineType) {
        this._machine = v;
    }

    toJSON() {
        return {
            id: this._id,
        }
    }

}