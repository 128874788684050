<div class="content">
    <div class="title bold">
        <span>{{ "GENERAL.LAST_MEMBRANE_CHANGE" | translate}}</span>
    </div>
    <a class="contact"
        [href]="'https://www.gea.com/en/contact/index.jsp?p=tcm%3A11-2930-64&f=&q1=service&country=DK&q2=1730'"
        target="_blank" rel="noopener noreferrer">
        <mat-icon aria-hidden="false" aria-label="concat" fontIcon="call" class="icon"></mat-icon>
        <span class="">{{ "GENERAL.CONTACT" | translate}}</span>
    </a>
    <div class="row">
        <span>{{ "GENERAL.LAST_CHANGE" | translate}}</span>
        <span class="bold">{{lastChange}}</span>
    </div>
    <div class="row">
        <span>{{ "GENERAL.SINCE_CHANGE" | translate}}</span>
        <span class="bold"> {{sinceChange}} {{'GENERAL.WEEKS' | translate }} </span>
    </div>
    <div class="row">
        <span>{{ "GENERAL.NUMBER_OF_CIP_CHANGE" | translate}}</span>
        <span class="bold">{{cips}} {{'GENERAL.CIP' | translate}}s</span>
    </div>
</div>