import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { EulaPopupComponent } from './core/shared/components/eula-popup/eula-popup.component';
import { GlobalService } from './core/shared/services/global.service';
import { Documents } from 'src/environments/constants';
import { environment } from 'src/environments/environment';

import {
  AboutPanelComponent,
  AboutPanelInputs,
  Environment as EnvironmentEnum,
  NavigationItem,
  SetEnvironment,
  SidePanel,
  SidepanelTypes,
  UserState
} from '@gea/digital-ui-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  // @ViewChild('header', { read: ElementRef }) header?: ElementRef;

  public title: string = 'GeaSmartFiltration';

  public contentReady: boolean = false;
  public noAuth: boolean = false;
  public sideBarOpen: string = '';

  public navItems: NavigationItem[] = [];

  private observer?: MutationObserver;

  constructor(
    private translate: TranslateService,
    private store: Store,
    private route: Router,
    private globalService: GlobalService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.navigate();

    this.store.dispatch(new SetEnvironment(environment.name || EnvironmentEnum.PROD));
    this.store.select(UserState.token).subscribe(token => { if (token) this.loadCookies() });

    this.translate.setDefaultLang('en-US');
    const selectedLanguage = localStorage.getItem('language');

    if (selectedLanguage && selectedLanguage.length > 2) {
      this.translate.use(selectedLanguage);
    } else {
      localStorage.setItem('language', 'en-US');
      this.translate.use('en-US');
    }
  }

  ngAfterViewInit(): void {
    // this.resize();
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  // private resize(): void {
  //   this.observer = new MutationObserver(m => {
  //     if (window.innerWidth < 1442) {
  //       this.sideBarOpen = '2%'
  //       return;
  //     }
  //     const nav = this.header?.nativeElement.querySelector('.nav-bar.full');
  //     this.sideBarOpen = nav ? 'calc(2% + 270px)' : '5%';
  //   })

  //   const domSideBar = this.header?.nativeElement;
  //   this.observer.observe(domSideBar, { childList: true, subtree: true });
  // }

  public openDataPrivacy(): void {
    window.open(Documents.DataPrivay);
  }

  public openTermsAndConditions(): void {
    window.open(Documents.TermsOfUse);
  }

  public openImprint(): void {
    window.open(Documents.Imprint);
  }

  public openEula(): void {
    window.open(Documents.Eula);
  }

  public openEulaPopUp(): void {
    this.dialog.open(EulaPopupComponent, { width: '50rem', disableClose: true });
  }

  public openCookieSettings(): void {
    // this syntax is necessary here to access onetrust
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window.OneTrust.ToggleInfoDisplay();
  }

  private loadCookies(): void {
    this.globalService.getUserMemberships().subscribe({
      next: () => {
        if (this.contentReady) return;
        this.globalService.hasEula().subscribe(eula => eula ? this.contentReady = true : this.openEulaPopUp())
      },
      error: () => this.globalService.logout()
    });
  }

  private navigate(): void {
    this.route.events.subscribe(res => {
      if (res instanceof NavigationEnd) this.translate.get("GENERAL.OK").subscribe(() => {
        const url = res.url.slice(0, res.url.indexOf('?')).split('/')[3];
        const id = parseInt(res.url.split('/')[2]);

        if (!id) {
          this.navItems = [
            {
              icon: 'account_tree',
              route: `/smartfiltration`,
              label: this.translate.instant('GENERAL.MACHINES')
            },
          ]
        } else {
          this.navItems = [
            {
              icon: 'account_tree',
              route: `/smartfiltration`,
              label: this.translate.instant('GENERAL.MACHINES')
            },
            {
              icon: 'home',
              route: `/smartfiltration/${id}/overview`,
              label: this.translate.instant('SMARTFILTRATION.OVERVIEW.TITLE')
            },
            {
              icon: 'factory',
              route: `/smartfiltration/${id}/production-list`,
              label: this.translate.instant('SMARTFILTRATION.PRODUCTION.TITLE')
            },
            {
              icon: 'cip',
              route: `/smartfiltration/${id}/cip-list`,
              label: this.translate.instant('SMARTFILTRATION.CIP.TITLE')
            },
            {
              icon: 'consumption',
              route: `/smartfiltration/${id}/consumption`,
              label: this.translate.instant('SMARTFILTRATION.CONSUMPTION.TITLE')
            },
            {
              icon: 'fluxtest',
              route: `/smartfiltration/${id}/flux-test`,
              label: this.translate.instant('SMARTFILTRATION.FLUX_TEST.TITLE')
            },
            {
              icon: 'chemical',
              route: `/smartfiltration/${id}/chemical`,
              label: this.translate.instant('SMARTFILTRATION.CHEMICAL.TITLE')
            },
            {
              icon: 'select_check_box',
              route: `/smartfiltration/${id}/service`,
              label: this.translate.instant('SMARTFILTRATION.SERVICE.TITLE')
            },
          ];
        }
      });
    })
  }

  public botItems: SidePanel[] = [
    {
      key: 'about',
      icon: '16px_info',
      label: 'UI-LIB.NAV-BAR.ABOUT',
      type: SidepanelTypes.FOOTER,
      ref: AboutPanelComponent,
      inputs: {
        copyright: 'GEA Group Services GmbH 2023',
        footers: [
          {
            key: 'imprint-header',
            header: 'UI-LIB.FOOTER.IMPRINT',
            content: [
              {
                key: 'imprint',
                icon: '24px_pdf',
                label: 'UI-LIB.FOOTER.IMPRINT',
                action: this.openImprint.bind(this),
              },
            ],
          },
          {
            key: 'terms-header',
            header: 'UI-LIB.FOOTER.DATA-PRIVACY',
            content: [
              {
                key: 'terms',
                icon: '24px_pdf',
                label: 'UI-LIB.FOOTER.DATA-PRIVACY',
                action: this.openDataPrivacy.bind(this),
              },
            ],
          },
          {
            key: 'terms-header',
            header: 'UI-LIB.FOOTER.TERMS-AND-CONDITIONS',
            content: [
              {
                key: 'terms',
                icon: '24px_pdf',
                label: 'UI-LIB.FOOTER.TERMS-AND-CONDITIONS',
                action: this.openTermsAndConditions.bind(this),
              },
            ],
          },
          {
            key: 'eula',
            header: 'UI-LIB.FOOTER.EULA',
            content: [
              {
                key: 'eula',
                icon: '24px_pdf',
                label: 'UI-LIB.FOOTER.EULA',
                action: this.openEula.bind(this),
              },
              {
                key: 'eula-dialog',
                icon: '24px_settings',
                label: 'UI-LIB.FOOTER.EULA',
                action: this.openEulaPopUp.bind(this),
              },
            ],
          },
          {
            key: 'cookie-settings',
            header: 'UI-LIB.FOOTER.COOKIE-SETTINGS',
            content: [
              {
                key: 'cookies',
                icon: '24px_settings',
                label: 'UI-LIB.FOOTER.COOKIES',
                action: this.openCookieSettings.bind(this),
              },
            ],
          },
        ],
      } as AboutPanelInputs
    },
  ];
}
