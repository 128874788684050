import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';

import { DragDropModule } from '@angular/cdk/drag-drop';


import { DomSanitizer } from '@angular/platform-browser';

const materialModules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDividerModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatDialogModule,
  MatGridListModule,
  MatMomentDateModule,
  MatIconModule,
  DragDropModule,
];

export const locale = window.navigator.language || 'en-EN';

@NgModule({
  declarations: [],
  imports: [
    ...materialModules
  ],
  exports: [
    ...materialModules
  ],
  providers: [
    // { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class MaterialModule {

  constructor(mdIconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    mdIconRegistry.addSvgIcon('account_tree', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/account_tree.svg'));
    mdIconRegistry.addSvgIcon('factory', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/factory.svg'));
    mdIconRegistry.addSvgIcon('home', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/home.svg'));
    mdIconRegistry.addSvgIcon('cip', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/CIP.svg'));
    mdIconRegistry.addSvgIcon('chemical', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/Chemical.svg'));
    mdIconRegistry.addSvgIcon('consumption', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/Consumption.svg'));
    mdIconRegistry.addSvgIcon('downtime', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/Downtime.svg'));
    mdIconRegistry.addSvgIcon('error', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/Error.svg'));
    mdIconRegistry.addSvgIcon('fluxtest', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/Fluxtest.svg'));
    mdIconRegistry.addSvgIcon('membrane', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/Membrane.svg'));
    mdIconRegistry.addSvgIcon('select_check_box', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/Select_check_box.svg'));
  }

}