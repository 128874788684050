import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ChartConfiguration } from 'chart.js';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { forkJoin, Observable, zip } from 'rxjs';
import { constants } from 'src/environments/constants';
import { Historian } from '../../models/interfaces/historian';
import { Machine } from '../../models/interfaces/machine';

import { Step } from '../../models/interfaces/step';
import { Value } from '../../models/interfaces/value';
import { DatepickerService } from '../../services/datepicker.service';

import { HistorianService } from '../../services/historian.service';
import { Request } from '../../models/classes/request';

@Component({
  selector: 'app-chemical',
  templateUrl: './chemical.component.html',
  styleUrls: ['./chemical.component.scss']
})
export class ChemicalComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  public barChartData: ChartConfiguration['data'] = { labels: [], datasets: [] };
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right'
      },
      zoom: {
        pan: {
          enabled: true,
          modifierKey: 'alt'
        },
        zoom: {
          drag: {
            enabled: true,
            threshold: 10,
          },
          mode: 'xy',
        },
        limits: {
          y: { min: 'original', max: 'original' }
        }
      },
      datalabels: {
        anchor: 'end',
        align: 'start',
        color: 'white',
        formatter: (value, context) => {
          const val = Number(value);
          return val.toLocaleString(undefined, { maximumFractionDigits: 2 });
        },
        rotation: 90
      },
      tooltip: {
        callbacks: {
          label: (label: any) => {
            const raw = parseFloat(label.raw);
            if (raw % 1 == 0) return label.formattedValue;
            const rawText = String(label.raw).replace(".", "");

            for (let i = 0; i < rawText.length; i++) {
              if (rawText[i] != '0') return raw.toLocaleString(undefined, { maximumFractionDigits: i + 2 });
            }

            return raw;
          }
        }
      }
    },
    onResize: (chart, size) => {
      if (chart.options.plugins?.legend) {
        if (size.width < 868) {
          chart.options.plugins.legend.position = 'top';
        } else {
          chart.options.plugins.legend.position = 'right';
        }
        chart.update();
      }
    }
  };

  public range: any = { start: new Date().toISOString(), end: new Date().toISOString() };

  title: string = "SMARTFILTRATION.CHEMICAL.TITLE";

  public contentReady: boolean = false;
  public $machine?: Observable<Machine>;

  private machineId?: number;
  private stepReady = 3;

  private steps: Record<string, Step> = {};
  private typeCip = constants.stepTypes.cip;
  private typeProduction = constants.stepTypes.production;

  private fields: string[] = []
  private colors: string[] = ['#0303b8', '#3C3C3C', '#1D3DF0', '#646464', '#3A78E7', '#8C8C8C'];

  constructor(private service: HistorianService, private active: ActivatedRoute, private datepicker: DatepickerService) {
    this.datepicker.datepickerClosed.subscribe(range => range ? this.batchRequest() : null);
  }

  ngOnInit(): void {
    this.active.params.subscribe(params => {
      this.machineId = parseInt(params['id']);
      this.$machine = this.service.getMachine(this.machineId);

      this.service.getMachineType(this.machineId).subscribe(m => {
        for (let x = 1; x <= m.detergentcount; x++) this.fields.push('DC_DetergentCIP_Actual_Type' + x);

        m.steps.forEach((s: Step) => this.steps[s.order] = s);
        this.batchRequest();
      });
    });
  }

  public resetZoom(event: MouseEvent): void {
    this.chart?.chart?.resetZoom();
  }

  private batchRequest(): void {
    this.range = this.datepicker.getRange();
    const request = this.service.fillRequest('UP01_Master_Sequence', this.range.start, this.range.end);

    this.service.getSteps(request).subscribe(table => {
      const temp = this.temporaryTable(table);
      const arr: Observable<Record<string, Historian>[]>[] = [];

      this.fields.forEach(f => {
        const req = temp.map((m => {
          const aux: Request = {
            field: f,
            endtime: m.timeend,
            starttime: m.timestart,
            measurement: request.measurement,
            bucket: request.bucket
          }

          return aux;
        }));

        arr.push(this.service.getPeakValues(req));
      });

      zip(arr).subscribe(e => this.fillChart(e, temp));
    });

  }

  private temporaryTable(data: Value[]): TempTable[] {
    let arr: any[] = [];
    let index = 0;

    while (index < data.length - 1) {
      const value = data[index];
      let type = this.typeProduction;
      let recipe = 'No record';

      index++;

      if (!this.steps[value.step].isready_marker) continue;

      let start = new Date(value.timestart);
      const startIndex = index - 1;
      while (index < data.length - 1 && (data[index].step > this.stepReady)) { index++ }
      let end = new Date(data[index].timestart);

      //Set process type name
      for (let i = index; i >= startIndex; i--) {
        if (this.steps[data[i].step]?.iscip_marker) {
          type = this.typeCip;
          break;
        }
      }

      //Set name for recipe
      if (type == this.typeCip) {
        for (let i = startIndex; i >= 0; i--) {
          if (data[i]?.cip_recipe != "") {
            recipe = data[i].cip_recipe;
            break;
          }
        }
      }
      else if (type == this.typeProduction) {
        for (let i = startIndex; i >= 0; i--) {
          if (data[i]?.prod_recipe != "") {
            recipe = data[i].prod_recipe;
            break;
          }
        }
      }

      let temp = moment.utc(end.getTime() - start.getTime());
      let duration = Number(temp) > 86400000 ? `${Math.floor(moment.duration(end.getTime() - start.getTime()).asDays())}d ` : ''
      duration += Number(temp) > 3600000 ? temp.format('HH[h] mm[m] ss[s]') : temp.format('mm[m] ss[s]');

      arr.push({
        start: startIndex,
        timestart: start.toISOString(),
        timeend: end.toISOString(),
        time: start.toLocaleString(),
        duration: duration,
        recipe: recipe,
        type: type
      });
    }

    arr = arr.slice(0, -1);
    return arr;
  }

  private fillChart(data: Record<string, Historian>[][], table: TempTable[]) {
    const { timeZone } = this.datepicker.getRange();
    let result: number[][] = [];

    for (let i = 0; i < data.length; i++) {
      const temp: number[] = [];

      for (let j = 0; j < data[i].length; j++) {
        temp[j] = 0;
        for (let e in data[i][j]) {
          temp[j] = parseFloat(data[i][j][e].value);
        }
      }

      result.push(temp);
    }

    //Prepare dataset's color and array for data
    const datasets: any[] = [];
    for (const key in this.fields) {
      datasets.push({
        data: [],
        label: `Detergent ${this.fields[key].split("_").slice(-1)}`,
        backgroundColor: this.colors[key],
        hoverBackgroundColor: this.colors[key],
        hoverBorderColor: this.colors[key],
        borderColor: this.colors[key],
      });
    }

    //Fill dataset's data with calculated values
    const labels: string[][] = [];
    for (let i = 0; i < table.length; i++) {
      labels.push([table[i].recipe, new Date(table[i].timestart).toLocaleString(undefined, { timeZone: timeZone })])
    }

    for (let i = 0; i < datasets.length; i++) {
      datasets[i].data = result[i];
    }

    this.barChartData.labels = labels;
    this.barChartData.datasets = datasets.sort((a, b) => a.label.localeCompare(b.label))
    this.contentReady = true;
    this.chart?.update();
  }

}

export interface TempTable {
  timestart: string,
  timeend: string,
  time: string,
  duration: string,
  recipe: string,
  type: string
}