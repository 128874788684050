import { Component, OnInit } from '@angular/core';
import { HistorianService } from '../../services/historian.service';

import { constants } from 'src/environments/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { DatepickerService } from '../../services/datepicker.service';

import { Gantt } from '../../models/classes/gantt';
import { Machine } from '../../models/interfaces/machine';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-list-of-production',
  templateUrl: './list-of-production.component.html',
  styleUrls: ['./list-of-production.component.scss']
})
export class ListOfProductionComponent implements OnInit {

  public data?: Gantt;
  public production = constants.stepTypes.production;

  public range: any = { start: new Date().toISOString(), end: new Date().toISOString() };

  title: string = "SMARTFILTRATION.PRODUCTION.TITLE";

  public $machine?: Observable<Machine>;

  private machineId: number = 0;

  constructor(private active: ActivatedRoute, private router: Router, private service: HistorianService, private datepicker: DatepickerService) {
    this.datepicker.datepickerClosed.subscribe(range => range ? this.fillData() : null);
  }

  ngOnInit(): void {
    this.active.params.subscribe(params => {
      this.machineId = parseInt(params['id'])
      this.$machine = this.service.getMachine(this.machineId);

      this.fillData();
    });
  }

  public onRowClick(row: any): void {
    const query = { typeId: this.machineId, timestart: row.timestart, timeend: row.timeend }
    this.router.navigate(['../process-details'], { relativeTo: this.active, queryParams: query });
  }

  private fillData(): void {
    this.range = this.datepicker.getRange();

    this.service.getMachineType(this.machineId).subscribe(res => {
      const request = this.service.fillRequest('UP01_Master_Sequence', this.range.start, this.range.end);

      this.service.getSteps(request).subscribe(steps => this.data = new Gantt({ id: 1, response: steps, machine: res, isProd: true }));
    });
  }

}