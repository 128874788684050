import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { NgChartsModule } from 'ng2-charts';
import { MsalRedirectComponent } from '@azure/msal-angular';

import { CoreModule } from './core/core.module';
import { MatDialogRef } from '@angular/material/dialog';

import {
  HeaderModule,
  AppEnvironmentState,
  ActionRequiredState,
  UserState,
  SidebarV2Module,
  PageLayoutModule,
  SnackbarModule,
  ComplexDialogV2Module,
  DialogV2Module
} from '@gea/digital-ui-lib';
import { TableV2Module } from '@gea/digital-ui-lib';

import { DigitalComponent } from './smartfiltration/components/graph/digital/digital.component';
import { TableComponent } from './smartfiltration/components/graph/table/table.component';
import { TrendV2Component } from './smartfiltration/components/graph/trend-v2/trend-v2.component';
import { OverviewComponent } from './smartfiltration/containers/overview/overview.component';
import { ProcessComponent } from './smartfiltration/containers/process/process.component';
import { VerticalBarComponent } from './smartfiltration/components/graph/vertical-bar/vertical-bar.component';
import { PieComponent } from './smartfiltration/components/graph/pie/pie.component';
import { CipComponent } from './smartfiltration/containers/cip/cip.component';
import { DatepickerComponent } from './smartfiltration/components/others/datepicker/datepicker.component';
import { CardLastChangeComponent } from './smartfiltration/components/others/card-last-change/card-last-change.component';
import { CardNextAuditComponent } from './smartfiltration/components/others/card-next-audit/card-next-audit.component';
import { ListOfCipComponent } from './smartfiltration/containers/list-of-cip/list-of-cip.component';
import { ListOfProductionComponent } from './smartfiltration/containers/list-of-production/list-of-production.component';
import { StatusTableComponent } from './smartfiltration/components/others/status-table/status-table.component';
import { GanttComponent } from './smartfiltration/components/graph/gantt/gantt.component';
import { ConsumptionComponent } from './smartfiltration/containers/consumption/consumption.component';
import { FluxTestComponent } from './smartfiltration/containers/flux-test/flux-test.component';
import { ChemicalComponent } from './smartfiltration/containers/chemical/chemical.component';
import { ServiceComponent } from './smartfiltration/containers/service/service.component';
import { TrendPopupComponent } from './smartfiltration/components/others/trend-popup/trend-popup.component';
import { TreeViewComponent } from './smartfiltration/containers/tree-view/tree-view.component';
import { ShowTimePopupComponent } from './smartfiltration/components/others/show-time-popup/show-time-popup.component';
import { HeaderComponent } from './smartfiltration/components/others/header/header.component';

import { NgxsModule } from '@ngxs/store';

import { MachineTypeDirective } from './smartfiltration/directives/machine-type.directive';
import { DigitalConsumComponent } from './smartfiltration/components/graph/digital-consum/digital-consum.component';
import { DigitalConsumV2Component } from './smartfiltration/components/graph/digital-consum-v2/digital-consum-v2.component';
import { DigitalConsumV3Component } from './smartfiltration/components/graph/digital-consum-v3/digital-consum-v3.component';


const translationFactory = (http: HttpClient) => {
  return new TranslateHttpLoader(http);
};

@NgModule({
  declarations: [
    AppComponent,
    DigitalComponent,
    TableComponent,
    TrendV2Component,
    OverviewComponent,
    ProcessComponent,
    VerticalBarComponent,
    PieComponent,
    CipComponent,
    DatepickerComponent,
    CardLastChangeComponent,
    CardNextAuditComponent,
    ListOfCipComponent,
    ListOfProductionComponent,
    StatusTableComponent,
    GanttComponent,
    ConsumptionComponent,
    FluxTestComponent,
    ChemicalComponent,
    ServiceComponent,
    TrendPopupComponent,
    TreeViewComponent,
    ShowTimePopupComponent,
    HeaderComponent,
    MachineTypeDirective,
    DigitalConsumComponent,
    DigitalConsumV2Component,
    DigitalConsumV3Component
  ],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgChartsModule,
    NgxsModule.forRoot([UserState, ActionRequiredState, AppEnvironmentState]),
    TranslateModule.forRoot({
      defaultLanguage: 'en-US',
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: translationFactory,
      },
    }),
    HeaderModule.forRoot({
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.postLogoutRedirectUri,
      clientId: environment.clientId,
      authority: environment.authority,
      authorityDomain: environment.authorityDomain,
      portalURL: environment.portalUrl,
      urlsToIntercept: environment.interceptorURLs ?? [],
      urls: {
        // userAdmin: '/user',
        // orgaAdmin: '/organization',
        // invitations: '/invitation',
        profile: 'user/profile/',
        resources: '/configuration/resources',
        permission: '',
        customers: '',
        // memberships: 'user/administration/users/${id}/memberships/',
        // orgasAndRoles: 'user/administration/organization/roles/',
      },
    }),
    TableV2Module,
    SidebarV2Module,
    PageLayoutModule
  ],
  providers: [
    HttpClientModule,
    TranslatePipe,
    { provide: MatDialogRef, useValue: {} },
    { provide: 'subscriptionKey', useValue: environment.subscriptionKey },
    { provide: 'storageURL', useValue: '' },
    { provide: 'baseUrl', useValue: environment.baseUrl },
    { provide: 'registrationBaseURL', useValue: '' },
    { provide: 'auditBaseURL', useValue: '' },
    { provide: 'iconPath', useValue: 'assets/icons/' },
    { provide: 'portalPath', useValue: 'portal-admin' }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})

export class AppModule { }
