import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { zip } from 'rxjs';
import { MachineType } from '../../../models/interfaces/machine-type';
import { HistorianService } from '../../../services/historian.service';

@Component({
  selector: 'app-card-next-audit',
  templateUrl: './card-next-audit.component.html',
  styleUrls: ['./card-next-audit.component.scss']
})
export class CardNextAuditComponent implements OnChanges {

  @Input() machine?: MachineType;
  @Input() load?: boolean;

  public lastAudit: string = '';
  public nextAudit: string = '';
  public notes: string = '';
  public weeks: number = 0;

  constructor(private active: ActivatedRoute, private service: HistorianService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.load) return;

    this.active.params.subscribe(params => {
      const id = parseInt(params['id'])

      this.service.getMachineType(id).subscribe(m => this.loadData(m));
    });
  }

  public loadData(machine: MachineType): void {
    this.lastAudit = new Date(machine.audit_last).toLocaleDateString();
    this.nextAudit = new Date(machine.audit_next).toLocaleDateString();

    this.notes = machine.audit_notes;


    const date = new Date(machine.audit_next).getTime() - new Date().getTime();
    this.weeks = date > 0 ? (Math.ceil(date / (1000 * 60 * 60 * 24)) / 7) : 0;
  }

}
