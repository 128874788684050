import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { BarValue } from 'src/app/smartfiltration/models/interfaces/bar-value';

@Component({
  selector: 'app-vertical-bar',
  templateUrl: './vertical-bar.component.html',
  styleUrls: ['./vertical-bar.component.scss']
})
export class VerticalBarComponent implements OnInit, OnChanges {

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  public barChartData: ChartConfiguration['data'] = { labels: [], datasets: [] };
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    resizeDelay: 100,
    scales: {
      x: {
        ticks: {
          maxRotation: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (label: any) => {
            const number = parseFloat(label.raw);
            if (isNaN(number) || number == 0) return number;
            if (number % 1 == 0) return number.toLocaleString(undefined, { maximumFractionDigits: 0 });

            const rawText = number.toString().split(".")[1];
            for (let i = 0; i < rawText.length; i++) {
              if (rawText[i] != '0') return number.toLocaleString(undefined, { maximumFractionDigits: i + 2 });
            }

            return label.formattedValue;
          }
        }
      },
      zoom: {
        pan: {
          enabled: true,
          modifierKey: 'alt'
        },
        zoom: {
          drag: {
            enabled: true,
            threshold: 10,
          },
          mode: 'xy',
        },
        limits: {
          y: { min: 'original', max: 'original' }
        }
      },
      datalabels: {
        anchor: 'end',
        align: 'start',
        color: 'white',
        clip: true,
        formatter: (value, context) => {
          const number = Number(value);
          if (isNaN(number) || number == 0) return number;
          if (context.chart.scales['y'].max % 1 == 0) return number.toLocaleString(undefined, { maximumFractionDigits: 0 });

          const decimals = number.toString().split(".")[1];
          const search = decimals?.search(/[1-9]/);
          if (search >= 0) return number.toLocaleString(undefined, { maximumFractionDigits: search + 2 });

          return value;
        },
        rotation: 90
      }
    },
  };

  @Input() data?: BarValue[];
  public contentReady: boolean = false;

  constructor() { }

  ngOnChanges(): void {
    if (!this.data) return;

    this.loadData();
  }

  ngOnInit(): void { }

  public resetZoom(event: MouseEvent): void {
    this.chart?.chart?.resetZoom();
  }

  private loadData(): void {
    if (!this.data) return;
    const datasets: any[] = [];
    const labels = this.data.map(x => [new Date(x.time).toLocaleTimeString(), new Date(x.time).toLocaleDateString(), x.recipe]);

    datasets.push({
      data: this.data.map(x => parseFloat(x.value)),
      backgroundColor: '#0303b8',
      hoverBackgroundColor: '#0303b8',
      hoverBorderColor: '#0303b8',
      borderColor: '#0303b8',
    })

    this.barChartData.labels = labels;
    this.barChartData.datasets = datasets;
    this.contentReady = true;
    this.chart?.update();
  }

}
