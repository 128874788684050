<app-header [title]="title | translate" [machine]="$machine"></app-header>

<div class="resume sf-card">
    <span class="title">{{ "SMARTFILTRATION.PRODUCTION.LIST_OF" | translate}}</span>

    <div class="table-h40">
        <app-table [data]="data" [filter]="production" (clickRow)="onRowClick($event)"></app-table>
    </div>
</div>

<mat-expansion-panel class="expansion" [expanded]="false">
    <mat-expansion-panel-header>
        <mat-panel-title>
            {{ "SMARTFILTRATION.CIP.GANTT" | translate}}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="gantt">
        <app-gantt [data]="data"></app-gantt>
    </div>
</mat-expansion-panel>