import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Request } from '../models/classes/request';
import { Enum } from '../models/interfaces/enum';

import { Historian } from '../models/interfaces/historian';
import { Machine } from '../models/interfaces/machine';
import { MachineType } from '../models/interfaces/machine-type';
import { Value } from '../models/interfaces/value';
import { EnumValue } from '../models/interfaces/enum-value';
import { Groups } from 'src/environments/constants';
import { EnumGroup } from '../models/interfaces/enum-group';

@Injectable({
  providedIn: 'root'
})
export class HistorianService {

  private hPath = environment.apiUrlHistorian;

  //THIS VARs ARE FILLED WHEN "GET MACHINE TYPE" IS CALLED (WITH ASSET_ID OR ORGANIZATION), 
  //LATER IS USED TO DO REQUEST ANY REQUEST WHICH NEED FILLREQUEST METHOD
  private _bucket: string;
  private _commissioningdate: string;

  constructor(protected httpClient: HttpClient) {
    this._bucket = "";
    this._commissioningdate = "";
  }

  public getCodexMessageDefinitionList() {
    return this.httpClient.get<any>(this.hPath + `/getCodexMessageDefinitionList`);
  }

  public getValues(params: Request): Observable<Record<string, Historian[]>> {
    let queryParameters = this.addHttpParams(params);
    return this.httpClient.get<Record<string, Historian[]>>(this.hPath + `/api/HistorianExec/HistoricalTags/Values`, { params: queryParameters }).pipe(take(1));
  }

  public getPeakValues(body: Request[]): Observable<Record<string, Historian>[]> {
    return this.httpClient.post<Record<string, Historian>[]>(this.hPath + `/api/HistorianExec/HistoricalTags/PeakValues`, body).pipe(take(1));
  }

  public getSummation(params: Request): Observable<any> {
    let queryParameters = this.addHttpParams(params);
    return this.httpClient.get<any[]>(this.hPath + `/api/HistorianExec/HistoricalTags/Summation`, { params: queryParameters }).pipe(take(1));
  }

  public getLastValue(params: Request): Observable<Record<string, Historian>> {
    let queryParameters = this.addHttpParams(params);
    return this.httpClient.get<Record<string, Historian>>(this.hPath + `/api/HistorianExec/HistoricalTags/LastValue`, { params: queryParameters }).pipe(take(1));
  }

  public getSteps(params: Request): Observable<Value[]> {
    let queryParameters = this.addHttpParams(params);
    queryParameters = queryParameters.append("commissioningdate", this._commissioningdate);
    return this.httpClient.get<Value[]>(this.hPath + `/api/HistorianExec/HistoricalTags/Steps`, { params: queryParameters }).pipe(take(1));
  }

  public getOOE(params: Request): Observable<any> {
    let queryParameters = this.addHttpParams(params);
    return this.httpClient.get<any>(this.hPath + `/api/HistorianExec/HistoricalTags/OEE`, { params: queryParameters });
  }

  public getEnumsProcess(): Observable<any[]> {
    return this.httpClient.get<Enum[]>(this.hPath + `/api/HistorianConfig/Enums/23/Values`);
  }

  public getEnumsCip(): Observable<any[]> {
    return this.httpClient.get<Enum[]>(this.hPath + `/api/HistorianConfig/Enums/25/Values`);
  }

  public getMachines(): Observable<Machine[]> {
    return this.httpClient.get<Machine[]>(this.hPath + `/api/HistorianConfig/Machines?lang_id=1033`).pipe(take(1));
  }

  public getMachine(id: number): Observable<Machine> {
    return this.httpClient.get<Machine>(this.hPath + `/api/HistorianConfig/Machines/${id}?lang_id=1033`).pipe(take(1));
  }

  public getMachineType(machineTypeId: number): Observable<MachineType> {
    return this.httpClient.get<MachineType>(this.hPath + `/api/HistorianConfig/Machines/${machineTypeId}/Type?lang_id=1033`).pipe(take(1),
      tap(t => {
        this._bucket = t.asset_id
        this._commissioningdate = t.commissioningdate;
      }));
  }

  public getEnumGroups(): Observable<EnumGroup[]> {
    return this.httpClient.get<EnumGroup[]>(this.hPath + `/api/HistorianConfig/Enums/Values?lang_id=1033`);
  }

  public getEnumValues(enumgroup_id: Groups): Observable<EnumValue[]> {
    return this.httpClient.get<EnumValue[]>(this.hPath + `/api/HistorianConfig/Enums/Values?enumgroup_id=${enumgroup_id}&lang_id=1033`);
  }

  public fillRequest(field: string, start: string, end: string, measurement?: string, bucket?: string, window?: string): Request {
    return new Request({
      field: field,
      endtime: end,
      starttime: start,
      measurement: measurement || 'General',
      bucket: bucket || this._bucket,
      window: window
    })
  }

  private addHttpParams(params: Request): HttpParams {
    let queryParameters = new HttpParams({
      fromObject: {
        starttime: params.starttime,
        endtime: params.endtime,
        bucket: params.bucket,
        measurement: params.measurement,
        field: params.field
      }
    });

    if (params.window) queryParameters = queryParameters.append("window", params.window);

    return queryParameters;
  }

}
