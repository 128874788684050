import { Component, OnInit, } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute } from '@angular/router';
import { ColumnDefinition } from '@gea/digital-ui-lib';

import * as moment from 'moment';
import { forkJoin, zip } from 'rxjs';
import { constants } from 'src/environments/constants';
import { ShowTimePopupComponent } from '../../components/others/show-time-popup/show-time-popup.component';

import { Digital } from '../../models/classes/digital';
import { Gantt } from '../../models/classes/gantt';
import { TrendV2 } from '../../models/classes/trend-v2';
import { MachineType } from '../../models/interfaces/machine-type';
import { Step } from '../../models/interfaces/step';
import { HistorianService } from '../../services/historian.service';
import { DatepickerService } from '../../services/datepicker.service';

@Component({
  selector: 'app-cip',
  templateUrl: './cip.component.html',
  styleUrls: ['./cip.component.scss']
})
export class CipComponent implements OnInit {

  public data: Record<string, any> = {};
  public date = { timestart: '', timeend: '', duration: 0 };

  public cipColumns: ColumnDefinition[] = [];
  public cipColumnsPr: ColumnDefinition[] = [];
  public cipGantt: ColumnDefinition[] = [];
  public cipGanttPr: ColumnDefinition[] = [];

  public contentReady: boolean = true;

  private machineId: number = 0;
  public machineType: number = 0;

  constructor(private service: HistorianService, private active: ActivatedRoute, private dialog: MatDialog, private dateService: DatepickerService) { }

  ngOnInit(): void {
    this.setColumns();

    zip(this.active.queryParams, this.active.params).subscribe(([query, params]) => {
      this.machineId = parseInt(params['id']);

      const duration = new Date(query['timeend']).getTime() - new Date(query['timestart']).getTime();
      this.date = { timestart: query['timestart'], timeend: query['timeend'], duration: duration };
      this.dialog.open(ShowTimePopupComponent, { data: { date: this.date, process: constants.stepTypes.cip } });

      this.service.getMachineType(this.machineId).subscribe(m => {
        this.machineType = m.machinetype_id;
        this.fillData(this.date.timestart, this.date.timeend, m);
        this.table(this.date.timestart, this.date.timeend, m);
      });
    });
  }

  private fillData(start: string, end: string, machine: MachineType): void {
    this.data['total_power'] = new Digital({ id: 1, title: 'Total Power CIP', meassure: machine.power_eu_name, request: this.service.fillRequest('DC_PowerCIP_Total_Act_Power', start, end), maxValue: true });
    this.data['max_power_consumption'] = new Digital({ id: 2, title: 'Peak Power Consumption During CIP', meassure: machine.power_eu_name, request: this.service.fillRequest('DC_PowerCIP_Total_ActualPower', start, end), maxValue: true });
    this.data['total_time'] = new Digital({ id: 3, title: 'Total Time CIP', meassure: "Sec", request: this.service.fillRequest('DC_PowerCIP_Total_Act_Time', start, end), maxValue: true });

    this.data['water_cip'] = new Digital({ id: 3, title: 'Water CIP', meassure: machine.totalisedvolume_eu_name, request: this.service.fillRequest('DC_WaterCIP_Total_Flow', start, end), maxValue: true });
    this.data['max_water_flow'] = new Digital({ id: 3, title: 'Water Flow during CIP', meassure: machine.flow_eu_name, request: this.service.fillRequest('DC_WaterCIP_ActualFlow', start, end), maxValue: true });
    this.data['water_time'] = new Digital({ id: 5, title: 'Water Time CIP', meassure: "Sec", request: this.service.fillRequest('DC_WaterCIP_Total_Time', start, end), maxValue: true });

    this.data['water_flush'] = new Digital({ id: 3, title: 'Peak Water Flush Total Flow CIP', meassure: machine.totalisedvolume_eu_name, request: this.service.fillRequest('DC_WaterFlush_Total_Flow', start, end), maxValue: true });
    this.data['max_water_flush_flow'] = new Digital({ id: 3, title: 'Peak Water Flush Flow During CIP', meassure: machine.flow_eu_name, request: this.service.fillRequest('DC_WaterFlush_ActualFlow', start, end), maxValue: true });
    this.data['water_flush_time'] = new Digital({ id: 6, title: 'Water Flush Time CIP', meassure: "Sec", request: this.service.fillRequest('DC_WaterFlush_Total_Time', start, end), maxValue: true });

    const skid = ['D04PT01', 'F01PT01', 'F03PT01', 'P01PT01', 'P01PT02'].toString();
    this.data['skid'] = new TrendV2({
      id: 8, title: "", meassure: machine.pressure_eu_name, request: this.service.fillRequest(skid, start, end), lines: [
        { id: 1, name: 'D04PT01', color: '#488f95', position: 'none' },
        { id: 2, name: 'F01PT01', color: '#3e464a', position: 'none' },
        { id: 3, name: 'F03PT01', color: '#8a963c', position: 'none' },
        { id: 4, name: 'P01PT01', color: '#ba2f29', position: 'none' },
        { id: 5, name: 'P01PT02', color: '#326fb0', position: 'none' },
      ]
    });

    //Fill Trends
    const matrix = [];

    for (let x = 1; x <= machine.loopcount; x++) {
      matrix[x - 1] = [`L0${x}TT01`, `L0${x}FT01`, `L0${x}PT01`, `L0${x}CT01`]
    }

    const temperatures = matrix.map(e => e[0]);
    this.data['temperature'] = new TrendV2({
      id: 8,
      title: "Loop Temperature",
      meassure: machine.temperature_eu_name,
      request: this.service.fillRequest(temperatures.toString(), start, end),
      lines: temperatures.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const flows = matrix.map(e => e[1]);
    this.data['flow'] = new TrendV2({
      id: 9,
      title: "Loop Flow",
      meassure: machine.flow_eu_name,
      request: this.service.fillRequest(flows.toString(), start, end),
      lines: flows.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const pressures = matrix.map(e => e[2]);
    this.data['pressure'] = new TrendV2({
      id: 7,
      title: "Loop Pressure",
      meassure: machine.pressure_eu_name,
      request: this.service.fillRequest(pressures.toString(), start, end),
      lines: pressures.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const conds = matrix.map(e => e[3]);
    this.data['conductivity'] = new TrendV2({
      id: 11,
      title: "Loop Conductivity",
      meassure: machine.conductivity_eu_name,
      request: this.service.fillRequest(conds.toString(), start, end),
      lines: conds.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const water = ['DC_WaterCIP_ActualFlow', 'DC_WaterFlush_ActualFlow'].toString();
    this.data['water'] = new TrendV2({
      id: 8, title: "Water Flow", meassure: machine.flow_eu_name, request: this.service.fillRequest(water, start, end), lines: [
        { id: 1, name: 'DC_WaterCIP_ActualFlow', color: '#488f95', position: 'none', field: 'DC_WaterCIP_ActualFlow' },
        { id: 2, name: 'DC_WaterFlush_ActualFlow', color: '#3e464a', position: 'none' },
      ]
    });

  }

  private table(start: string, end: string, machine: MachineType): void {
    const { timeZone } = this.dateService.getRange();
    const loopInformation = []

    for (let x = 1; x <= machine.loopcount; x++) {
      loopInformation.push(`L0${x}TT01_SV`, `L0${x}FT01_SV`, `L0${x}PT01_SV`, `L0${x}FT01_pr_m2_SV`);
    }

    const request = {
      loop: this.service.getLastValue(this.service.fillRequest(loopInformation.toString(), start, end)),
      master: this.service.getSteps(this.service.fillRequest('UP01_Master_Sequence', start, end)),
      control: this.service.getSteps(this.service.fillRequest('OP02_CIP_Control', start, end)),
    }

    forkJoin(request).subscribe(({ loop, master, control }) => {
      let steps: Record<number, Step> = {};
      machine.steps.forEach((s: any) => steps[s.order] = s);
      let cipSteps: Record<number, Step> = {};
      machine.cipsteps.forEach((s: any) => cipSteps[s.order] = s);

      let arr: any[] = []
      for (let x = 1; x <= machine.loopcount; x++) {
        arr.push({
          loop: x,
          time: new Date(loop[`L0${x}TT01_SV`]?.time).toLocaleString(undefined, { timeZone: timeZone }),
          temperature: this.calcValue(loop[`L0${x}TT01_SV`]?.value, machine.temperature_eu_name),
          flow: this.calcValue(loop[`L0${x}FT01_SV`]?.value, machine.flow_eu_name),
          pressure: this.calcValue(loop[`L0${x}PT01_SV`]?.value, machine.pressure_eu_name),
          flow_pr: this.calcValue(loop[`L0${x}FT01_pr_m2_SV`]?.value, machine.flow_eu_name),

        });
      }

      const tableMaster = master.map(x => ({ step: steps[x.step]?.name, start: new Date(x.timestart).toLocaleString(), duration: x.durationseconds }));
      const tableControl = control.map(x => ({ step: cipSteps[x.step]?.name, start: new Date(x.timestart).toLocaleString(), duration: x.durationseconds }));

      if (tableMaster[0] && tableMaster[0].duration <= 0) tableMaster.shift();
      if (tableMaster[tableMaster.length - 1] && tableMaster[tableMaster.length - 1].duration <= 0) tableMaster.pop();

      this.data['master'] = new Gantt({ id: 1, response: master, machine: machine });
      this.data['tableMaster'] = tableMaster.map(x => ({ step: x.step, start: x.start, duration: this.getDuration(x.duration) }));


      if (tableControl[0] && tableControl[0].duration <= 0) tableControl.shift();
      if (tableControl[tableControl.length - 1] && tableControl[tableControl.length - 1].duration <= 0) tableControl.pop();
      this.data['control'] = new Gantt({ id: 1, response: control, machine: machine, useCipSteps: true });
      this.data['tableControl'] = tableControl.map(x => ({ step: x.step, start: x.start, duration: this.getDuration(x.duration) }));

      this.contentReady = false;
      this.data['tableLoop'] = arr;
    })
  }

  private setColumns(): void {
    const width = (window.innerWidth * 5) / 100;

    this.cipColumns = [
      { displayName: "Time", key: "time" },
      { displayName: "Loop", key: "loop" },
      { displayName: "Temperature", key: "temperature" },
      { displayName: "Pressure", key: "pressure" },
      { displayName: "Flow", key: "flow" },
    ];

    this.cipColumnsPr = [
      { displayName: "Time", key: "time" },
      { displayName: "Loop", key: "loop" },
      { displayName: "Temperature", key: "temperature" },
      { displayName: "Pressure", key: "pressure" },
      { displayName: "Flow", key: "flow" },
      { displayName: "Flow pr", key: "flow_pr" },
    ];

    this.cipGantt = [
      { displayName: "Step Name", key: "step" },
      { displayName: "Start Time", key: "start" },
      { displayName: "Total Time", key: "duration" },
    ];
  }

  private getDuration(value: number) {
    let temp = moment.utc(value * 1000);
    let duration = Number(temp) > 86400000 ? `${Math.floor(moment.duration(value).asDays())}d ` : ''
    duration += Number(temp) > 3600000 ? temp.format('HH[h] mm[m] ss[s]') : temp.format('mm[m] ss[s]');
    return duration;
  }

  private calcValue(value: string, unit: string = ''): string {
    if (!+value) return '';
    const res = Number(value) > 100 ? parseInt(value).toLocaleString() : parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: 2 });
    return res + ` ${unit}`;
  }

}