<div class="container">
    <div class="sf-card">
        <span class="title">
            {{ "GENERAL.PROCESS" | translate }} : {{ "SMARTFILTRATION.CIP.TITLE" | translate }}
            ||
            {{ "GENERAL.START" | translate }}: {{date.timestart | proxyDate}}
            -
            {{ "GENERAL.END" | translate }}: {{date.timeend | proxyDate}}
            ||
            {{ "GENERAL.TOTAL" | translate }}
            {{ "GENERAL.TIME" | translate }}: {{date.duration | proxyDate : 'duration'}}
        </span>
    </div>

    <mat-expansion-panel #digitals class="panel" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ "GENERAL.PROCESS" | translate}}
                {{ "SMARTFILTRATION.CIP.COUNTER" | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="expansion-content counter" *ngIf="digitals.expanded">
            <div class="digitals">
                <div class="clock">
                    <app-digital [data]="data['total_power']"></app-digital>
                </div>
                <div class="clock">
                    <app-digital [data]="data['max_power_consumption']"></app-digital>
                </div>
                <div class="clock">
                    <app-digital [data]="data['total_time']"></app-digital>
                </div>
            </div>
            <div class="digitals">
                <div class="clock">
                    <app-digital [data]="data['water_cip']"></app-digital>
                </div>
                <div class="clock">
                    <app-digital [data]="data['max_water_flow']"></app-digital>
                </div>
                <div class="clock">
                    <app-digital [data]="data['water_time']"></app-digital>
                </div>
            </div>
            <div class="digitals">
                <div class="clock">
                    <app-digital [data]="data['water_flush']"></app-digital>
                </div>
                <div class="clock">
                    <app-digital [data]="data['max_water_flush_flow']"></app-digital>
                </div>
                <div class="clock">
                    <app-digital [data]="data['water_flush_time']"></app-digital>
                </div>
            </div>
            <div #pr class="table" [machineType]="machineType" [type]="1">
                <geaui-table-v2 class="cip-table" [columnDefinitions]="cipColumnsPr" [data]="data['tableLoop']"
                    [loading]="contentReady"></geaui-table-v2>
            </div>
            <div class="table" *ngIf="pr.style.display == 'none'">
                <geaui-table-v2 class="cip-table" [columnDefinitions]="cipColumns" [data]="data['tableLoop']"
                    [loading]="contentReady"></geaui-table-v2>
            </div>
        </div>
    </mat-expansion-panel>


    <mat-expansion-panel class="panel">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ "SMARTFILTRATION.CIP.GANTT" | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="expansion-content">
            <div class="gantt-chart">
                <div class="table table-h40">
                    <geaui-table-v2 class="cip-table-2" [columnDefinitions]="cipGantt" [data]="data['tableMaster']"
                        [loading]="contentReady"></geaui-table-v2>
                </div>
                <div class="gantt">
                    <app-gantt [data]="data['master']"></app-gantt>
                </div>
            </div>
            <mat-expansion-panel class="panel" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ "SMARTFILTRATION.CIP.CIP_STEP" | translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="expansion-content">
                    <div class="gantt-chart">
                        <div class="table table-h40">
                            <geaui-table-v2 class="cip-table-2" [columnDefinitions]="cipGantt"
                                [data]="data['tableControl']" [loading]="contentReady"></geaui-table-v2>
                        </div>
                        <div class="gantt-CIP">
                            <app-gantt [data]="data['control']"></app-gantt>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel #trends class="panel">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ "SMARTFILTRATION.CIP.ANALOG" | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="expansion-content" *ngIf="trends.expanded">
            <div class="skid">
                <app-trend-v2 [data]="data['skid']"></app-trend-v2>
            </div>

            <div class="trend">
                <div class="left">
                    <app-trend-v2 [data]="data['pressure']"></app-trend-v2>
                </div>
                <div class="right">
                    <app-trend-v2 [data]="data['temperature']"></app-trend-v2>
                </div>
            </div>
            <div class="trend">
                <div class="left">
                    <app-trend-v2 [data]="data['flow']"></app-trend-v2>
                </div>
                <div class="right">
                    <app-trend-v2 [data]="data['conductivity']"></app-trend-v2>
                </div>
            </div>

            <div class="skid">
                <app-trend-v2 [data]="data['water']"></app-trend-v2>
            </div>
        </div>
    </mat-expansion-panel>
</div>