<div class="container">
    <div class="top">
        <span class="title"> Session Timeout </span>
        <button class="btn-close" (click)="refresh()">
            <mat-icon class="btn-close-icon" fontIcon="close"></mat-icon>
        </button>
    </div>
    <div class="content">
        <p>Your session has expired due to inactivity. Refresh your browser to start a new session.</p>

        <button mat-flat-button class="button" (click)="refresh()">
            Refresh
        </button>
    </div>
</div>