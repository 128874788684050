<app-header [title]="title | translate" [machine]="$machine"></app-header>

<div class="resume">
    <div class="table table-h35">
        <div class="sf-card">
            <span class="title">{{ "SMARTFILTRATION.FLUX_TEST.LATEST_FLUX" | translate}}</span>

            <div #pr class="graph" [machineType]="machineType" [type]="1">
                <geaui-table-v2 [columnDefinitions]="fluxColumns" [data]="wholeData"
                    [loading]="contentReady"></geaui-table-v2>
            </div>

            <div class="graph" *ngIf="pr.style.display == 'none'">
                <geaui-table-v2 class="flux-table" [columnDefinitions]="fluxColumnsPr" [data]="wholeData"
                    [loading]="contentReady"></geaui-table-v2>
            </div>
        </div>

        <div class="sf-card">
            <div class="trend">
                <app-trend-v2 [data]="data['conductivity']"></app-trend-v2>
            </div>
        </div>
    </div>

    <div class="status sf-card">
        <span class="title">{{ "SMARTFILTRATION.FLUX_TEST.STATUS" | translate}}</span>

        <div class="graph">
            <div class="trend">
                <app-trend-v2 [data]="data['pressure']"></app-trend-v2>
            </div>
            <div class="trend">
                <app-trend-v2 [data]="data['temperature']"></app-trend-v2>
            </div>
            <div class="trend">
                <app-trend-v2 [data]="data['flow']"></app-trend-v2>
            </div>
            <div class="trend" [machineType]="machineType" [type]="1">
                <app-trend-v2 [data]="data['flowPR']"></app-trend-v2>
            </div>
        </div>
    </div>
</div>