import { Chart } from "./chart";
import { LineV2 } from "./line-v2";

export class TrendV2 extends Chart {

    private _x: number;
    private _y: number;
    private _duration: number;
    private _lines: LineV2[];
    private _title: string;
    private _meassure: string;

    constructor(obj?: any) {
        super(obj);
        this._x = obj.x;
        this._y = obj.y;
        this._duration = obj.duration || 20000;
        this._title = obj.title || ""
        this._lines = [];
        this._meassure = obj.meassure || '';

        obj.lines?.forEach((element: any) => { this._lines.push(new LineV2(element)) });
    }

    public get x(): number {
        return this._x;
    }
    public set x(v: number) {
        this._x = v;
    }

    public get y(): number {
        return this._y;
    }

    public set y(v: number) {
        this._y = v;
    }

    public get duration(): number {
        return this._duration;
    }

    public set duration(v: number) {
        this._duration = v;
    }

    public get title(): string {
        return this._title;
    }

    public set title(v: string) {
        this._title = v;
    }

    public get lines(): LineV2[] {
        return this._lines;
    }

    public set lines(v: LineV2[]) {
        this._lines = v;
    }

    public get meassure(): string {
        return this._meassure;
    }

    public set meassure(v: string) {
        this._meassure = v;
    }

    override toJSON() {
        return {
            id: this.id,
            x: this._x,
            y: this._y,
            duration: this._duration,
            lines: this._lines
        }
    }

}