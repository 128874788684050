import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MachineType } from '../../../models/interfaces/machine-type';
import { Step } from '../../../models/interfaces/step';
import { HistorianService } from '../../../services/historian.service';

@Component({
  selector: 'app-card-last-change',
  templateUrl: './card-last-change.component.html',
  styleUrls: ['./card-last-change.component.scss']
})
export class CardLastChangeComponent implements OnChanges {

  @Input() load?: boolean;

  public lastChange: string = '';
  public sinceChange: number = 0;
  public cips: number = 0;


  constructor(private active: ActivatedRoute, private service: HistorianService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.load) return;

    this.active.params.subscribe(params => {
      const id = parseInt(params['id'])

      this.service.getMachineType(id).subscribe(m => this.loadData(m));
    });
  }

  private loadData(machine: MachineType): void {
    if (!machine) return;
    this.calculateCips(machine);
    this.calculateFields(machine)
  }

  private calculateFields(machine: MachineType): void {
    const date = Math.abs(new Date(machine.membranereplacement_last).getTime() - new Date().getTime());
    let diffDays = date > 0 ? Math.ceil(date / (1000 * 60 * 60 * 24)) : 0;

    this.lastChange = new Date(machine.membranereplacement_last).toLocaleDateString();
    this.sinceChange = (diffDays < 7) ? diffDays : Math.ceil(diffDays / 7);
  }

  private calculateCips(machine: MachineType): void {
    let steps: Record<string, Step> = {};
    machine.steps.forEach((s: Step) => steps[s.order] = s);

    let count = 0;
    let index = 0;

    this.service.getSteps(this.service.fillRequest('UP01_Master_Sequence', machine.membranereplacement_last, new Date().toISOString())).subscribe(data => {
      while (index < data.length - 1) {
        const before = data[index];
        index++;

        if (steps[data[index].step]?.iscip_marker) {

        } else if (steps[before.step]?.isready_marker) {

          let start = new Date(before.timestart);
          while (index < data.length - 1 && !steps[data[index].step]?.isready_marker && !steps[data[index].step]?.iscip_marker && data[index].step > 3) { index++ }
          let end = new Date(data[index].timestart);

          count++;
        }
      }

      this.cips = count;
    })
  }

}
