import { Component, OnInit } from '@angular/core';
import { concat, Observable } from 'rxjs';
import { Machine } from '../../models/interfaces/machine';
import { HistorianService } from '../../services/historian.service';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit {

  public machines: Machine[] = []

  constructor(private service: HistorianService) { }

  ngOnInit(): void {
    this.service.getMachines().subscribe(m => this.machines = m);
  }

}
