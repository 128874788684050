import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, switchMap, take } from 'rxjs';

import { Store } from '@ngxs/store';
import { UserState } from '@gea/digital-ui-lib';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private store: Store) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url;

    if (url.search('i18n') >= 0 || url.search('azure-api.net') >= 0 || url.search('data:image') >= 0) return next.handle(request);

    return this.store.select(UserState.token)
      .pipe(
        take(1),
        switchMap((token) => {
          let res = request.clone({ setHeaders: { Authorization: `Bearer ${token}` }, withCredentials: true });
          return next.handle(res);
        })
      );
  }
}
