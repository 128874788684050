import { Chart } from "./chart";

export class Gantt extends Chart {

    private _isCip: boolean;
    private _isProd: boolean;
    private _useCipSteps: boolean;

    constructor(obj?: any) {
        super(obj);
        this._isCip = obj.isCip || false;
        this._isProd = obj.isProd || false;
        this._useCipSteps = obj.useCipSteps || false;
    }

    public get useCipSteps(): boolean {
        return this._useCipSteps;
    }

    public set useCipSteps(v: boolean) {
        this._useCipSteps = v;
    }

    public get isCip(): boolean {
        return this._isCip;
    }

    public set isCip(v: boolean) {
        this._isCip = v;
    }

    public get isProd(): boolean {
        return this._isProd;
    }

    public set isProd(v: boolean) {
        this._isProd = v;
    }
}