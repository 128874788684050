import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Logout, LogoutFailed, RequestLogout, UserState } from '@gea/digital-ui-lib';
import { Store } from '@ngxs/store';
import { Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private hPath = environment.apiUrlHistorian;

  constructor(protected httpClient: HttpClient, private authService: MsalService, private store: Store) { }

  public hasEula(): Observable<boolean> {
    return this.httpClient.get<boolean>(this.hPath + `/api/HistorianInfo/Eula`).pipe(take(1));
  }

  public postEula(): Observable<boolean> {
    return this.httpClient.post<boolean>(this.hPath + `/api/HistorianInfo/Eula`, {});
  }

  public deleteEula(): Observable<boolean> {
    return this.httpClient.delete<boolean>(this.hPath + `/api/HistorianInfo/Eula`);
  }

  public getUserMemberships(): Observable<any> {
    return this.httpClient.get<any>(this.hPath + `/api/HistorianInfo/User`).pipe(take(1))
  }

  public logout(): void {
    const currentAccount = this.authService.instance.getActiveAccount();
    const logoutUri = environment.postLogoutRedirectUri;
    this.store.dispatch(new RequestLogout());

    this.store.select(UserState.token).subscribe((token) => {
      if (token && currentAccount && logoutUri) {
        const logoutRequest = {
          account: currentAccount,
          postLogoutRedirectUri: environment.postLogoutRedirectUri,
          idTokenHint: token,
        };

        this.authService
          .logoutRedirect(logoutRequest)
          .subscribe({
            next: () => this.store.dispatch(new Logout()),
            error: () => this.store.dispatch(new LogoutFailed()),
          });
      }
    });
  }

}
