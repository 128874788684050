import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'proxyDate'
})
export class ProxyDatePipe implements PipeTransform {

  transform(value: string | number, ...args: unknown[]): string {
    if (args.find(e => e == 'duration')) {
      let temp = moment.utc(Number(value));
      let duration = Number(temp) > 86400000 ? `${Math.floor(moment.duration(value).asDays())}d ` : ''
      duration += Number(temp) > 3600000 ? temp.format('HH[h] mm[m] ss[s]') : temp.format('mm[m] ss[s]');
      return duration;
    }

    if (args.find(e => e == 'timeZone')) {
      const timeZone = args[1] ? String(args[1]) : undefined;
      return new Date(value).toLocaleString(undefined, { timeZone: timeZone });
    }

    return new Date(value).toLocaleString(undefined);
  }

}
