<app-header [title]="title | translate" [machine]="$machine"></app-header>

<div class="resume sf-card">
    <span class="title">{{ "SMARTFILTRATION.CHEMICAL.CONSUMPTION" | translate}}</span>

    <div class="graph">
        <div class="label">
            <span>{{ "SMARTFILTRATION.CHEMICAL.LABEL" | translate}}</span>
        </div>
        <div class="center" (dblclick)="resetZoom($event)">
            <mat-spinner class="spinner" [diameter]="40" *ngIf="!contentReady"></mat-spinner>
            <canvas baseChart [data]="barChartData" [options]="barChartOptions" [type]="'bar'" *ngIf="contentReady">
            </canvas>
        </div>
    </div>
</div>