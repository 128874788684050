<mat-spinner class="spinner" [diameter]="40" *ngIf="!contentReady"></mat-spinner>
<div class="digital" *ngIf="contentReady">
    <div class="title" [style.font-size.px]="data?.titleSize">
        <span>{{data?.title}}</span>
        <a class="btn-performance" (click)="redirect()" *ngIf="data?.altBtn"
            [matTooltip]="'GENERAL.CO2_EMISSION' | translate">
            <mat-icon class="btn-performance-icon" fontIcon="open_in_new"></mat-icon>
            <span>{{'GENERAL.INFO' | translate}}</span>
        </a>
    </div>
    <div class="content">
        <span [style.color]="data?.color" [style.font-size.px]="data?.size" [style.font-weight]="data?.weigth"
            [style.font-style]="data?.style">
            {{ value }}
        </span>
        <span [style.color]="data?.color" [style.font-size.px]="(data)? data.size/2 : 10"
            [style.font-weight]="data?.weigth" [style.font-style]="data?.style">
            {{data?.meassure || 0}}
        </span>
    </div>
</div>