<app-header [title]="title | translate" [machine]="$machine"></app-header>

<div class="container">
    <mat-expansion-panel #service>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ "SMARTFILTRATION.OVERVIEW.CONTACT" | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="indicator">
            <div class="contact">
                <app-card-last-change [load]="service.expanded"></app-card-last-change>
            </div>
            <div class="contact">
                <app-card-next-audit [load]="service.expanded"></app-card-next-audit>
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel #panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ "SMARTFILTRATION.OVERVIEW.STATUS" | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <app-status-table [data]="data['status']" [load]="panel.expanded"></app-status-table>
    </mat-expansion-panel>

    <div class="resume">
        <div class="table table-h35 sf-card">
            <span class="title">{{ "SMARTFILTRATION.OVERVIEW.LIST_OF" | translate}}</span>

            <div class="graph">
                <app-table [data]="data['table']" (clickRow)="onRowClick($event)"></app-table>
            </div>
        </div>
        <div class="chart sf-card">
            <span class="title">{{ "SMARTFILTRATION.OVERVIEW.CIRCLE" | translate}}</span>

            <mat-tab-group>
                <mat-tab label="30 Days">
                    <ng-template matTabContent>
                        <app-pie [data]="data['30days']"></app-pie>
                    </ng-template>
                </mat-tab>
                <mat-tab label="7 Days">
                    <ng-template matTabContent>
                        <app-pie [data]="data['07days']"></app-pie>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="digital sf-card">
            <span class="title">{{ "SMARTFILTRATION.OVERVIEW.CONSUMPTION" | translate}}</span>
            <div class="clock">
                <app-digital-consum-v3 [data]="data['kwh']"></app-digital-consum-v3>
            </div>
            <div class="clock">
                <app-digital-consum-v3 [data]="data['co2']"></app-digital-consum-v3>
            </div>
            <div class="daterange" *ngIf="!contentReady">
                <span>
                    {{ 'GENERAL.FROM' | translate }}
                    {{ range.start | proxyDate : 'timeZone' : range.timeZone }}
                    {{ 'GENERAL.TO' | translate }}
                    {{ range.end | proxyDate : 'timeZone' : range.timeZone }}
                </span>
            </div>
        </div>
    </div>

    <div class="trends sf-card">
        <span class="title">{{ "SMARTFILTRATION.OVERVIEW.FLUX" | translate}}</span>

        <div #pr class="table" [machineType]="this.machineType" [type]="1">
            <geaui-table-v2 class="overview-table-flux" [columnDefinitions]="cipColumnsPr" [data]="tableData"
                [loading]="contentReady">
            </geaui-table-v2>
        </div>

        <div class="table" *ngIf="pr.style.display == 'none'">
            <geaui-table-v2 class="overview-table-flux" [columnDefinitions]="cipColumns" [data]="tableData"
                [loading]="contentReady">
            </geaui-table-v2>
        </div>

        <div class="row">
            <div class="col">
                <app-trend-v2 [data]="data['pressure']"></app-trend-v2>
            </div>
            <div class="col">
                <app-trend-v2 [data]="data['temperature']"></app-trend-v2>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <app-trend-v2 [data]="data['flow']"></app-trend-v2>
            </div>
            <div class="col" [machineType]="this.machineType" [type]="1">
                <app-trend-v2 [data]="data['flowPR']"></app-trend-v2>
            </div>
        </div>
    </div>
</div>