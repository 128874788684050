<div class="content">
    <div class="tabs">
        <div class="tab" (click)="tab = 0" [ngClass]="{'selected': tab == 0}" id="absolute">
            <span class="text">{{ "DATEPICKER.ABSOLUTE" | translate }}</span>
        </div>
        <div class="tab" (click)="tab = 1" [ngClass]="{'selected': tab == 1}">
            <span class="text">{{ "DATEPICKER.QUICK_RANGE" | translate }}</span>
            <span class="status">{{ getTimeRangeName() | translate }}</span>
        </div>
        <div class="tab" (click)="tab = 2" [ngClass]="{'selected': tab == 2}">
            <span class="text">{{ "DATEPICKER.TIME_ZONE" | translate }}</span>
            <span class="status">{{ getTimeZoneName() | translate}}</span>
        </div>
    </div>

    <div class="template">
        <ng-container #absolute *ngIf="tab == 0">
            <div class="calendars">
                <div class="calendar">
                    <mat-calendar [selected]="calendar" [startAt]="calendar.start"
                        (selectedChange)="dateChange($event)">
                    </mat-calendar>
                </div>
                <div class="calendar">
                    <mat-calendar [selected]="calendar" [startAt]="calendar.end" (selectedChange)="dateChange($event)">
                    </mat-calendar>
                </div>
            </div>

            <form [formGroup]="dateForm" (submit)="submit()" class="form">
                <div class="time">
                    <span>{{"GENERAL.ADD_TIME" | translate}}</span>
                </div>
                <div class="inputs">
                    <input matInput type="datetime-local" formControlName="start" step="1">

                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="arrow_forward"
                        id="form-element"></mat-icon>

                    <input matInput type="datetime-local" formControlName="end" step="1" id="form-element">


                    <mat-checkbox class="checkbox" formControlName="checkbox" id="form-element">{{"GENERAL.ALL_DAY" |
                        translate}}</mat-checkbox>
                </div>

                <div class="buttons" id="calendar-buttons">
                    <button class="button" type="button" (click)="close()" mat-flat-button>
                        {{"GENERAL.CANCEL" | translate}}
                    </button>
                    <button class="button" id="ok-button" type="submit" mat-flat-button>
                        {{"GENERAL.OK" | translate}}
                    </button>
                </div>
            </form>
        </ng-container>

        <ng-container #quickRange *ngIf="tab == 1">
            <div class="quick-range">
                <div class="cell" [class.selected]="selectedRange === 1">
                    <a (click)="setTimeRange(1)">{{"DATEPICKER.LAST_60" | translate}}</a>
                </div>

                <div class="cell" [class.selected]="selectedRange === 2">
                    <a (click)="setTimeRange(2)">{{"DATEPICKER.TODAY" | translate}}</a>
                </div>

                <div class="cell" [class.selected]="selectedRange === 3">
                    <a (click)="setTimeRange(3)">{{"DATEPICKER.LAST_24" | translate}}</a>
                </div>

                <div class="cell" [class.selected]="selectedRange === 4">
                    <a (click)="setTimeRange(4)">{{"DATEPICKER.YESTERDAY" | translate}}</a>
                </div>

                <div class="cell" [class.selected]="selectedRange === 5">
                    <a (click)="setTimeRange(5)">{{"DATEPICKER.LAST_7" | translate}}</a>
                </div>

                <div class="cell" [class.selected]="selectedRange === 6">
                    <a (click)="setTimeRange(6)">{{"DATEPICKER.LAST_WEEK" | translate}}</a>
                </div>

                <div class="cell" [class.selected]="selectedRange === 7">
                    <a (click)="setTimeRange(7)">{{"DATEPICKER.LAST_30" | translate}}</a>
                </div>

                <div class="cell" [class.selected]="selectedRange === 8">
                    <a (click)="setTimeRange(8)">{{"DATEPICKER.LAST_MONTH" | translate}}</a>
                </div>

                <div class="cell" [class.selected]="selectedRange === 9">
                    <a (click)="setTimeRange(9)">{{"DATEPICKER.LAST_90" | translate}}</a>
                </div>

                <div class="cell" [class.selected]="selectedRange === 10">
                    <a (click)="setTimeRange(10)">{{"DATEPICKER.LAST_QUARTER" | translate}}</a>
                </div>
            </div>

            <div class="buttons">
                <button class="button" type="button" (click)="close()" mat-flat-button>
                    {{"GENERAL.CANCEL" | translate}}
                </button>
                <button class="button" type="submit" id="ok-button" (click)="submit()" mat-flat-button>
                    {{"GENERAL.OK" | translate}}
                </button>
            </div>
        </ng-container>
        <ng-container #timeZone *ngIf="tab == 2">
            <div class="quick-range" id="time-zone">
                <div class="div1 cell" [class.selected]="selectedTimeZone == 0">
                    <a (click)="setTimeZone(0)">{{"DATEPICKER.LOCAL" | translate}}</a>
                </div>
                <div class="div2 cell" [class.selected]="selectedTimeZone == 1">
                    <a (click)="setTimeZone(1)">{{"DATEPICKER.UTC" | translate}}</a>
                </div>
            </div>

            <div class="buttons">
                <button class="button" type="button" (click)="close()" mat-flat-button>
                    {{"GENERAL.CANCEL" | translate}}
                </button>
                <button class="button" type="submit" (click)="submit()" id="ok-button" mat-flat-button>
                    {{"GENERAL.OK" | translate}}
                </button>
            </div>
        </ng-container>
    </div>
</div>