<div class="trend">
    <div class="title" *ngIf="data?.title">
        <span>{{data?.title}}</span>
    </div>
    <div class="panel">
        <mat-spinner class="spinner" [diameter]="40" *ngIf="!contentReady"></mat-spinner>
        <div class="center" *ngIf="contentReady" (dblclick)="resetZoom($event)">
            <canvas baseChart [data]="lineChartData" [options]="lineChartOptions" [type]="'line'"></canvas>
        </div>
    </div>
</div>