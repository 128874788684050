import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-inactivity-popup',
  templateUrl: './inactivity-popup.component.html',
  styleUrls: ['./inactivity-popup.component.scss']
})
export class InactivityPopupComponent {

  constructor( public dialogRef: MatDialogRef<InactivityPopupComponent> ) {
    dialogRef.backdropClick().subscribe(() => {
      this.refresh();
    });
  }

  refresh() {
    location.reload();
    this.dialogRef.close();
  }

}