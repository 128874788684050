<div class="daterange">
    <span>{{'GENERAL.FROM' | translate}} {{start}} {{'GENERAL.TO' | translate}} {{end}}</span>
</div>
<div class="status">
    <div class="col-1">
        <div class="row-1">
            <span class="title">{{ "SMARTFILTRATION.STATUS.PRODUCTION" | translate}}</span>

            <table>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.RECIPE_CODE" | translate}}</td>
                    <td> {{rec['Prod_Recipe_Code']}} </td>
                </tr>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.RECIPE_NAME" | translate}}</td>
                    <td> {{rec['Prod_Recipe']}} </td>
                </tr>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.MASTER_STEP" | translate}}</td>
                    <td> {{ materStepName }} </td>
                </tr>
            </table>
        </div>

        <div class="row-2">
            <span class="title">{{ "SMARTFILTRATION.STATUS.CIP" | translate}}</span>

            <table>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.RECIPE_CODE" | translate}}</td>
                    <td> {{rec['CIP_Recipe_code']}} </td>
                </tr>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.RECIPE_NAME" | translate}}</td>
                    <td> {{rec['CIP_Recipe']}} </td>
                </tr>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.CIP_STEP" | translate}}</td>
                    <td> {{ cipStepName }}</td>
                </tr>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.CIP_COUNTER" | translate}}</td>
                    <td> {{rec['OP02_StepCounter_AV']}} </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="col-2">
        <table>
            <tr>
                <td></td>
                <td class="center" class="center">{{ "SMARTFILTRATION.STATUS.SET_POINT" | translate}}</td>
                <td class="center">{{ "SMARTFILTRATION.STATUS.PROCESS_VALUE" | translate}}</td>
            </tr>
            <tr>
                <td>{{ "SMARTFILTRATION.STATUS.PRODUCT_FLOW" | translate}}</td>
                <td class="center">
                    {{ rec['F03_Product_flow_SP'] | number : calcDecimals(rec['F03_Product_flow_SP']) }}
                </td>
                <td class="center">
                    {{ rec['F03FT01'] | number : calcDecimals(rec['F03FT01']) }}
                    {{rec['F03FT01']? data?.machine?.flow_eu_name : ''}}
                </td>
            </tr>
            <tr>
                <td>{{ "SMARTFILTRATION.STATUS.PRODUCT_TOTAL" | translate}}</td>
                <td class="center"> </td>
                <td class="center">
                    {{ rec['UP01_Prod_Amount_Counter_AV'] | number : calcDecimals(rec['UP01_Prod_Amount_Counter_AV']) }}
                    {{ rec['UP01_Prod_Amount_Counter_AV']? data?.machine?.totalisedvolume_eu_name : '' }}
                </td>
            </tr>
            <tr>
                <td>{{ "SMARTFILTRATION.STATUS.LEVEL_CIP_TANK" | translate}}</td>
                <td class="center"> </td>
                <td class="center">
                    {{ rec['F03LT01'] | number : '1.0-2' }}
                    {{ rec['F03LT01']? '%' : '' }}
                </td>
            </tr>
            <tr>
                <td>{{ "SMARTFILTRATION.STATUS.BASELINE_PRESSURE" | translate}}</td>
                <td class="center"> </td>
                <td class="center">
                    {{ rec['F03PT01'] | number : calcDecimals(rec['F03PT01']) }}
                    {{rec['F03PT01']? data?.machine?.pressure_eu_name : ''}}
                </td>
            </tr>
            <tr>
                <td>{{ "SMARTFILTRATION.STATUS.RETENTATE_FLOW" | translate}} </td>
                <td class="center"> </td>
                <td class="center">
                    {{ rec['R01FT01'] | number : calcDecimals(rec['R01FT01']) }}
                    {{ rec['R01FT01']? data?.machine?.flow_eu_name : '' }}
                </td>
            </tr>
            <tr>
                <td>{{ "SMARTFILTRATION.STATUS.RETENTATE_TOTAL" | translate}}</td>
                <td class="center"> </td>
                <td class="center">
                    {{ rec['UP01_Ret_Amount_Counter_AV'] | number : calcDecimals(rec['UP01_Ret_Amount_Counter_AV']) }}
                    {{ rec['UP01_Ret_Amount_Counter_AV']? data?.machine?.totalisedvolume_eu_name : '' }}
                </td>
            </tr>
            <tr>
                <td>{{ "SMARTFILTRATION.STATUS.RETENTATE_RATIO" | translate}}</td>
                <td class="center">
                    {{ rec['R01_Retentate_Ratio_SP'] | number : calcDecimals(rec['R01_Retentate_Ratio_SP']) }} </td>
                <td class="center">
                    {{ rec['R01_RatioActual_AV'] | number : calcDecimals(rec['R01_RatioActual_AV']) }}
                    {{ rec['R01_RatioActual_AV']? (data?.machine?.totalisedvolume_eu_name + '/' +
                    data?.machine?.totalisedvolume_eu_name) : ''}}
                </td>
            </tr>
            <tr [machineType]="machineType" [type]="1">
                <td>{{ "SMARTFILTRATION.STATUS.DAW_FILTRATION_FLOW" | translate}}</td>
                <td class="center"> </td>
                <td class="center"> {{ rec['D04FT01'] | number : calcDecimals(rec['D04FT01']) }}</td>
            </tr>
            <tr [machineType]="machineType" [type]="1">
                <td>{{ "SMARTFILTRATION.STATUS.DAW_FILTRATION_TOTAL" | translate}}</td>
                <td class="center">
                    {{ rec['UP01_Batch_DAW_Tot_SP'] | number : calcDecimals(rec['UP01_Batch_DAW_Tot_SP']) }}
                </td>
                <td class="center">
                    {{ rec['UP01_Batch_DAW_Counter_AV'] | number : calcDecimals(rec['UP01_Batch_DAW_Counter_AV']) }}
                    {{ rec['UP01_Batch_DAW_Counter_AV']? data?.machine?.flow_eu_name : '' }}
                </td>
            </tr>
            <tr [machineType]="machineType" [type]="0">
                <td>{{ "SMARTFILTRATION.STATUS.PERMEATE_TOTAL" | translate}}</td>
                <td class="center">
                    {{ rec['UP01_Perm_Tot_SP'] | number : calcDecimals(rec['UP01_Perm_Tot_SP']) }}
                </td>
                <td class="center">
                    {{ rec['UP01_Perm_Amount_Counter_AV'] | number : calcDecimals(rec['UP01_Perm_Amount_Counter_AV']) }}
                    {{ rec['UP01_Perm_Amount_Counter_AV']? data?.machine?.totalisedvolume_eu_name : '' }}
                </td>
            </tr>
        </table>
    </div>
    <div class="col-3">
        <div class="row-1">
            <table>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.LOOP" | translate}}</td>
                    <td class="center" *ngFor="let l of loops">-{{l}}-</td>
                </tr>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.STATE" | translate}}</td>
                    <td class="center" *ngFor="let l of loops">{{ getStateName(rec['L0'+l+'_State']) }}</td>
                </tr>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.PRESSURE" | translate}}</td>
                    <td class="center" *ngFor="let l of loops">
                        {{ rec['L0'+l+'PT01'] | number : calcDecimals(rec['L0'+l+'PT01']) }}
                        {{ rec['L0'+l+'PT01']? data?.machine?.pressure_eu_name : '' }}
                    </td>
                </tr>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.TEMPERATURE" | translate}}</td>
                    <td class="center" *ngFor="let l of loops">
                        {{ rec['L0'+l+'TT01'] | number : calcDecimals(rec['L0'+l+'TT01']) }}
                        {{ rec['L0'+l+'TT01']? data?.machine?.temperature_eu_name : '' }}
                    </td>
                </tr>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.PERMEATE_FLOW" | translate}}</td>
                    <td class="center" *ngFor="let l of loops">
                        {{ rec['L0'+l+'FT01'] | number : calcDecimals(rec['L0'+l+'FT01']) }}
                        {{ rec['L0'+l+'FT01']?data?.machine?.flow_eu_name : '' }}
                    </td>
                </tr>
                <tr>
                    <td>{{ "SMARTFILTRATION.STATUS.CONDUCTIVITY" | translate}}</td>
                    <td class="center" *ngFor="let l of loops">
                        {{ rec['L0'+l+'CT01'] | number : calcDecimals(rec['L0'+l+'CT01']) }}
                        {{ rec['L0'+l+'CT01']? data?.machine?.conductivity_eu_name : '' }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<div class="remote">
    <button (click)="redirect()">
        <mat-icon aria-hidden=" false" aria-label="remote" fontIcon="aod_tablet"></mat-icon>
        <span>{{ "SMARTFILTRATION.STATUS.REMOTE" | translate}}</span>
    </button>
</div>
<div class="trend">
    <app-trend-v2 [data]="trend"></app-trend-v2>
</div>