import { Component, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Digital } from '../../../models/classes/digital';
import { HistorianService } from '../../../services/historian.service';
import { Value } from 'src/app/smartfiltration/models/interfaces/value';
import { constants } from 'src/environments/constants';
import { Step } from 'src/app/smartfiltration/models/interfaces/step';
import { forkJoin, zip } from 'rxjs';

@Component({
  selector: 'app-digital-consum-v2',
  templateUrl: './digital-consum-v2.component.html',
  styleUrls: ['./digital-consum-v2.component.scss']
})
export class DigitalConsumV2Component {

  @Input() data?: Digital;

  public value: string = '';
  public contentReady: boolean = false;

  private cip = constants.stepTypes.cip;
  private production = constants.stepTypes.production;
  private stepReady: number = 3;

  constructor(private service: HistorianService, public dialog: MatDialog) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.data || !this.data.request) return;

    const stepRequest = {
      field: "UP01_Master_Sequence",
      endtime: this.data?.request.endtime,
      starttime: this.data?.request.starttime,
      measurement: this.data?.request.measurement,
      bucket: this.data?.request.bucket
    }

    const valuesRequest = {
      field: this.data.request.field,
      endtime: this.data?.request.endtime,
      starttime: this.data?.request.starttime,
      measurement: this.data?.request.measurement,
      bucket: this.data?.request.bucket,
      window: '1s'
    }

    forkJoin({
      steps: this.service.getSteps(stepRequest),
      values: this.service.getValues(valuesRequest)
    }).subscribe(({ steps, values }) => {
      const arrProcess = this.loadListOfProcess(steps);

      let result = 0;

      for (let k in values) {
        const field = values[k].map(m => ({ time: new Date(m.time).getTime(), value: Number(m.value) }))
        let aux = 0;

        for (let p of arrProcess) {
          for (let i = aux; i < field.length; i++) {
            if (field[i].time < p.timestart) continue;
            aux = i;
            break;
          }

          const a = aux;

          for (let i = aux; i < field.length; i++) {
            if (field[i].time < p.timeend) continue;
            aux = i;
            break;
          }

          const map = field.slice(a, aux).map(x => x.value * (this.data?.adjust || 1));
          if (map.length <= 0) continue;
          result += Math.max(...map);
        }
      }

      this.value = result.toLocaleString(undefined, { maximumFractionDigits: this.data?.decimals });;
      this.contentReady = true;

    });

  }

  private loadListOfProcess(data: Value[]): Process[] {
    let arr: Process[] = [];
    let index = 0;

    const steps: Record<string, Step> = {};
    this.data?.machine.steps.forEach((s: Step) => steps[s.order] = s);

    while (index < data.length - 1) {
      const value = data[index];
      let type = this.production;

      index++;

      if (!steps[value.step].isready_marker) continue;

      let start = new Date(value.timestart);
      const startIndex = index - 1;
      while (index < data.length - 1 && (data[index].step > this.stepReady)) { index++ }
      let end = new Date(data[index].timestart);

      //Set process type name
      for (let i = index; i >= startIndex; i--) {
        if (steps[data[i].step]?.iscip_marker) {
          type = this.cip;
          break;
        }
      }
      arr.push({
        start: startIndex,
        timestart: start.getTime(),
        timeend: end.getTime(),
        type: type
      });
    }

    //Remove first entry if it's not a complete process
    if (data.at(0)?.step == this.stepReady) arr = arr.slice(1);

    //Remove last entry to avoid uncomplete process
    arr = arr.slice(0, -1);

    return arr;
  }

  public redirect(): void {
    window.open('https://www.eea.europa.eu/ims/greenhouse-gas-emission-intensity-of-1', '_blank');
  }
}

export interface Process {
  start: number,
  timeend: number,
  timestart: number,
  type: string
}