import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnDefinition, TableSettingsService } from '@gea/digital-ui-lib';

import { Observable } from 'rxjs';
import { constants } from 'src/environments/constants';
import { TrendV2 } from '../../models/classes/trend-v2';
import { Historian } from '../../models/interfaces/historian';
import { Machine } from '../../models/interfaces/machine';
import { MachineType } from '../../models/interfaces/machine-type';
import { Step } from '../../models/interfaces/step';
import { DatepickerService } from '../../services/datepicker.service';
import { HistorianService } from '../../services/historian.service';

@Component({
  selector: 'app-flux-test',
  templateUrl: './flux-test.component.html',
  styleUrls: ['./flux-test.component.scss']
})
export class FluxTestComponent implements OnInit {

  public range: any = { start: new Date().toISOString(), end: new Date().toISOString() };

  title: string = "SMARTFILTRATION.FLUX_TEST.TITLE";

  public wholeData: TableValue[] = [];

  public fluxColumns: ColumnDefinition[] = [];
  public fluxColumnsPr: ColumnDefinition[] = [];
  public contentReady: boolean = true;
  public $machine?: Observable<Machine>;

  public data: Record<string, any> = {};

  private machineId: number = 0;
  public machineType: number = 0;

  constructor(private active: ActivatedRoute, private service: HistorianService, private datepicker: DatepickerService, private tableSettings: TableSettingsService) {
    this.datepicker.datepickerClosed.subscribe(range => range ? this.getMachineType() : null);
  }

  ngOnInit(): void {
    this.active.params.subscribe(params => {
      this.machineId = parseInt(params['id']);
      this.$machine = this.service.getMachine(this.machineId);

      this.setColumns();
      this.getMachineType();
    });
  }


  private getMachineType() {
    this.range = this.datepicker.getRange();

    this.service.getMachineType(this.machineId).subscribe(m => {
      let steps: Record<number, Step> = {};

      m.steps.forEach((s: any) => steps[s.order] = s);
      this.machineType = m.machinetype_id;

      this.fillData(m);
    })
  }

  private setColumns(): void {
    this.fluxColumns = [
      { displayName: "Loop", key: "loop" },
      { displayName: "Temperature", key: "temperature" },
      { displayName: "Pressure", key: "pressure" },
      { displayName: "Flow", key: "flow" },
      { displayName: "Flow pr", key: "flow_pr" },
      { displayName: "Time", key: "time" },
    ]

    this.fluxColumnsPr = [
      { displayName: "Loop", key: "loop" },
      { displayName: "Temperature", key: "temperature" },
      { displayName: "Pressure", key: "pressure" },
      { displayName: "Flow", key: "flow" },
      { displayName: "Time", key: "time" },
    ]
  }

  private fillData(machine: MachineType) {
    const loopInformation: string[] = [];
    for (let x = 1; x <= machine.loopcount; x++) {
      loopInformation.push(`L0${x}TT01_SV`, `L0${x}FT01_SV`, `L0${x}PT01_SV`, `L0${x}FT01_pr_m2_SV`, `L0${x}CT01`)
    }

    this.service.getValues(this.service.fillRequest(loopInformation.toString(), this.range.start, this.range.end)).subscribe(res => {
      this.fillCharts(res, machine);
      this.fillTable(res, machine);
    })
  }

  private fillCharts(res: Record<string, Historian[]>, machine: MachineType) {
    const matrix = [];
    for (let x = 1; x <= machine.loopcount; x++) {
      matrix[x - 1] = [`L0${x}TT01_SV`, `L0${x}FT01_SV`, `L0${x}PT01_SV`, `L0${x}FT01_pr_m2_SV`, `L0${x}CT01`]
    }

    const temperatures = matrix.map(e => e[0]);
    this.data['temperature'] = new TrendV2({
      id: 8,
      title: "Temperature",
      meassure: machine.temperature_eu_name,
      response: temperatures.reduce((a, v) => ({ ...a, [v]: res[v] }), {}),
      lines: temperatures.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const pressures = matrix.map(e => e[2]);
    this.data['pressure'] = new TrendV2({
      id: 7,
      title: "Pressure",
      meassure: machine.pressure_eu_name,
      response: pressures.reduce((a, v) => ({ ...a, [v]: res[v] }), {}),
      lines: pressures.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });


    const flows = matrix.map(e => e[1]);
    this.data['flow'] = new TrendV2({
      id: 9,
      title: "Flow",
      meassure: machine.flow_eu_name,
      response: flows.reduce((a, v) => ({ ...a, [v]: res[v] }), {}),
      lines: flows.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const flowPRs = matrix.map(e => e[3]);
    this.data['flowPR'] = new TrendV2({
      id: 10,
      title: "Flow pr m^2",
      meassure: machine.flow_eu_name,
      response: flowPRs.reduce((a, v) => ({ ...a, [v]: res[v] }), {}),
      lines: flowPRs.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const conductivity = matrix.map(e => e[4]);
    this.data['conductivity'] = new TrendV2({
      id: 10,
      title: "Conductivity",
      meassure: machine.conductivity_eu_name,
      response: conductivity.reduce((a, v) => ({ ...a, [v]: res[v] }), {}),
      lines: conductivity.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });
  }


  private fillTable(res: Record<string, Historian[]>, machine: MachineType): void {
    const { timeZone } = this.datepicker.getRange();

    let map: Map<string, Record<string, string>> = new Map();
    for (let key in res) {
      res[key].forEach(element => {
        if (!map.has(element.time)) map.set(element.time, {});
        map.get(element.time)![key] = element.value
      })
    }

    let arr: TableValue[] = [];
    for (const [key, value] of map) {
      for (let x = 1; x <= machine.loopcount; x++) {
        arr.push({
          loop: x,
          time: new Date(key).toLocaleString(undefined, { timeZone: timeZone }),
          mills: new Date(key).getTime(),
          temperature: this.calcValue(value[`L0${x}TT01_SV`], machine.temperature_eu_name),
          flow: this.calcValue(value[`L0${x}FT01_SV`], machine.flow_eu_name),
          pressure: this.calcValue(value[`L0${x}PT01_SV`], machine.pressure_eu_name),
          flow_pr: this.calcValue(value[`L0${x}FT01_pr_m2_SV`], machine.flow_eu_name),
        })
      }
    }

    arr = arr.sort((x, y) => y.mills - x.mills);
    this.wholeData = arr.filter(x => x.temperature != '' || x.flow != '' || x.pressure != '');

    this.contentReady = false;
  }

  private calcValue(value: string, unit: string = ''): string {
    if (!+value) return '';
    const res = Number(value) > 100 ? parseInt(value).toLocaleString() : parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: 2 });
    return res + ` ${unit}`;
  }

}

export interface TableValue {
  loop: number,
  time: string,
  mills: number,
  temperature: string,
  flow: string,
  pressure: string,
  flow_pr: string
}