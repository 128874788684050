import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-time-popup',
  templateUrl: './show-time-popup.component.html',
  styleUrls: ['./show-time-popup.component.scss']
})
export class ShowTimePopupComponent {

  public date: { timestart: string, timeend: string, duration: number } = { timestart: '', timeend: '', duration: 0 };
  public process: string = '';

  constructor(
    public dialogRef: MatDialogRef<ShowTimePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.date = this.data.date;
    this.process = this.data.process;
  }

  public close(): void {
    this.dialogRef.close();
  }

}
