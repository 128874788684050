import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Chart } from '../../../models/classes/chart';
import { TrendV2 } from '../../../models/classes/trend-v2';
import { HistorianService } from '../../../services/historian.service';
import { Groups, constants } from 'src/environments/constants';
import { DatepickerService } from 'src/app/smartfiltration/services/datepicker.service';

@Component({
  selector: 'app-status-table',
  templateUrl: './status-table.component.html',
  styleUrls: ['./status-table.component.scss']
})
export class StatusTableComponent implements OnInit, OnChanges {

  @Input() data?: Chart;
  @Input() load: boolean = false;

  public rec: Record<string, string> = {};
  public trend: any;

  public start: string = "";
  public end: string = "";
  public loops: number[] = [];

  public cipSteps: Record<number, string> = {};
  public prodSteps: Record<number, string> = {};

  public machineType: number = 0;
  public loopStates: Record<number, string> = {};

  public get materStepName(): string {
    return this.data?.machine.steps.find(f => String(f.order) == this.rec['UP01_Master_Sequence'])?.name ?? '';
  }

  public get cipStepName(): string {
    return this.data?.machine.cipsteps.find(f => String(f.order) == this.rec['OP02_CIP_Control'])?.name ?? '';
  }

  constructor(private service: HistorianService, private dateService: DatepickerService) { }

  ngOnInit(): void { }

  ngOnChanges(): void {
    if (!this.data || !this.load) return;
    const { timeZone } = this.dateService.getRange();

    this.start = new Date(this.data.request.starttime).toLocaleString(undefined, { timeZone: timeZone });
    this.end = new Date(this.data.request.endtime).toLocaleString(undefined, { timeZone: timeZone });
    this.loops = [...Array(this.data?.machine?.loopcount + 1).keys()].slice(1);
    this.machineType = this.data.machine.machinetype_id;

    this.loadData();
  }

  public redirect(): void {
    window.open('https://m2web.talk2m.com/', '_blank')
  }

  public getStateName(statusId: string): string {
    return +statusId ? this.loopStates[+statusId] : statusId;
  }

  public calcDecimals(value: any): string {
    return Number(value) > 100 ? '1.0-0' : '1.0-2'
  }

  private loadData(): void {
    if (!this.data || !this.data.request) return;

    const start = this.data.request.starttime;
    const end = this.data.request.endtime;
    const bucket = this.data.machine.asset_id;

    //Fill Charts
    const analaogs = ['F01PT01', 'F03PT01', 'P01PT01', 'P01PT02'];
    for (let x = 1; x <= this.loops.length; x++) analaogs.push(`L0${x}PT01`);
    analaogs.sort();

    this.service.getValues(this.service.fillRequest(analaogs.toString(), start, end, undefined, bucket)).subscribe(res => {
      this.trend = new TrendV2({
        id: 1, title: "Analog Process Values",
        meassure: this.data?.machine.pressure_eu_name,
        response: res,
        lines: analaogs.map((e, i) => ({ id: i, name: e, color: constants.colors[i], position: 'none', field: e }))
      });
    });

    //Fill tables
    const requ = [
      'Prod_Recipe_Code',
      'Prod_Recipe',
      'UP01_Master_Sequence',

      'CIP_Recipe_code',
      'CIP_Recipe',
      'OP02_CIP_Control',
      'OP02_StepCounter_AV',

      'UP01_Par_02_Conc',
      'UP01_Par_01_Tank_Vol',
      'UP01_Par_03_DAW',
      'F01_Par_01_Disp_Vol',

      'F03_Product_flow_SP',
      'UP01_Prod_Amount_Counter_AV',

      'F01PT01',
      'F03PT01',
      'P01PT01',
      'P01PT02',

      'F03FT01',
      'F03LT01',
      'R01FT01',

      'UP01_Ret_Amount_Counter_AV',
      'R01_Retentate_Ratio_SP',
      'R01_RatioActual_AV',

      'D04FT01',
      'UP01_Batch_DAW_Tot_SP',
      'UP01_Batch_DAW_Counter_AV',

      'UP01_Perm_Tot_SP',
      'UP01_Perm_Amount_Counter_AV'
    ]

    for (let x = 1; x <= this.loops.length; x++) requ.push(`L0${x}_State`, `L0${x}PT01`, `L0${x}TT01`, `L0${x}FT01`, `L0${x}CT01`);

    this.service.getLastValue(this.service.fillRequest(requ.toString(), start, end, undefined, bucket)).subscribe(res => {
      for (let key in res) {
        this.rec[key] = res[key].value;
      }
    });

    this.service.getEnumValues(Groups.LoopState).subscribe(res => res.forEach(e => this.loopStates[e.enumgroupvalue_no] = e.name));
  }

}
