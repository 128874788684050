import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { Machine } from '../../../models/interfaces/machine';

import { DatepickerService } from "../../../services/datepicker.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() title: string | undefined;
  @Input() machine?: Observable<Machine>;

  public range: any = this.datepicker.getRange();
  public datePicker: boolean = false;

  constructor(private datepicker: DatepickerService) { }

  public closeDatePicker(event: any) {
    this.datePicker = false;
    this.range = this.datepicker.getRange();

    if (event) this.datepicker.datepickerClosed.next(event);
  }

}
