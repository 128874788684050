import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { map } from 'rxjs';
import { Digital } from '../../../models/classes/digital';
import { HistorianService } from '../../../services/historian.service';
import { TrendPopupComponent } from '../../others/trend-popup/trend-popup.component';

@Component({
  selector: 'app-digital',
  templateUrl: './digital.component.html',
  styleUrls: ['./digital.component.scss']
})
export class DigitalComponent implements OnInit {

  @Input() data?: Digital;

  public value: string = '';
  public contentReady: boolean = false;

  constructor(private service: HistorianService, public dialog: MatDialog) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    (this.data?.maxValue) ? this.loadMaxValue() : (this.data?.sumValue) ? this.loadSumValue() : this.loadLastValue();
  }

  private loadLastValue(): void {
    if (!this.data) return;
    this.service.getLastValue(this.data.request).subscribe(res => {
      let value: number = 0;
      for (let e in res) value += Number(res[e].value);
      this.value = value.toLocaleString();

      this.contentReady = true;
    });
  }

  private loadMaxValue() {
    if (!this.data) return;
    this.service.getValues(this.data.request).
      pipe(map(res => {
        let result = 0;
        for (let e in res) {
          const max = Math.max(...res[e].map(m => Number(m.value)));
          result = result >= max ? result : max;
        }
        return result;
      })).subscribe(res => {
        this.value = (res * (this.data?.adjust || 1)).toLocaleString(undefined, { maximumFractionDigits: this.data?.decimals });
        this.contentReady = true;
      });
  }

  private loadSumValue() {
    if (!this.data) return;
    this.service.getValues(this.data.request).
      pipe(map(res => {
        let result = 0;
        for (let e in res) {
          result += res[e].reduce((pm, m) => pm + Number(m.value), 0);
        }
        return result;
      })).subscribe(res => {
        this.value = (res * (this.data?.adjust || 1)).toLocaleString(undefined, { maximumFractionDigits: this.data?.decimals });
        this.contentReady = true;
      });
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(TrendPopupComponent, {
      data: this.data,
    });
  }

  public redirect(): void {
    window.open('https://www.eea.europa.eu/ims/greenhouse-gas-emission-intensity-of-1', '_blank');
  }

}
