import { Chart } from "./chart";

export class Digital extends Chart {

    private _size: number;
    private _titleSize: number;
    private _color: string;
    private _font: string;
    private _weight: string;
    private _style: string;
    private _meassure: string;
    private _adjust: number;
    private _title: string;
    private _maxValue: boolean;
    private _sumValue: boolean;
    private _decimals: number;
    private _altBtn: boolean;





    constructor(obj?: any) {
        super(obj);
        this._size = obj.size || 24;
        this._titleSize = obj.titleSize || 14;
        this._color = obj.color ? obj.color : '#FFFFFF';
        this._font = obj.font || "";
        this._weight = obj.weigth || "900"
        this._style = obj.style || "normal"
        this._meassure = obj.meassure || "meassure";
        this._adjust = obj.adjust;
        this._title = obj.title || "Title";
        this._maxValue = obj.maxValue || false;
        this._sumValue = obj.sumValue || false;
        this._decimals = obj.decimals || 0;
        this._altBtn = obj.altBtn || false;
    }

    public get size(): number {
        return this._size;
    }

    public set size(v: number) {
        this._size = v;
    }

    public get titleSize(): number {
        return this._titleSize;
    }

    public set titleSize(v: number) {
        this._titleSize = v;
    }

    public get color(): string {
        return this._color;
    }

    public set color(v: string) {
        this._color = v;
    }

    public get font(): string {
        return this._font;
    }

    public set font(v: string) {
        this._font = v;
    }

    public get weigth(): string {
        return this._weight;
    }

    public set weigth(v: string) {
        this._weight = v;
    }

    public get style(): string {
        return this._style;
    }

    public set style(v: string) {
        this._style = v;
    }

    public get meassure(): string {
        return this._meassure;
    }

    public set meassure(v: string) {
        this._meassure = v;
    }

    public get adjust(): number {
        return this._adjust;
    }

    public set adjust(v: number) {
        this._adjust = v;
    }

    public get title(): string {
        return this._title;
    }

    public set title(v: string) {
        this._title = v;
    }

    public get maxValue(): boolean {
        return this._maxValue;
    }
    public set maxValue(v: boolean) {
        this._maxValue = v;
    }

    public get sumValue(): boolean {
        return this._sumValue;
    }
    public set sumValue(v: boolean) {
        this._sumValue = v;
    }

    public get decimals(): number {
        return this._decimals;
    }

    public set decimals(v: number) {
        this._decimals = v;
    }

    public get altBtn(): boolean {
        return this._altBtn;
    }
    public set altBtn(v: boolean) {
        this._altBtn = v;
    }

}