import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Machine } from '../../models/interfaces/machine';

import { HistorianService } from '../../services/historian.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  public $machine?: Observable<Machine>;

  private machineId: number = 0;

  constructor(private service: HistorianService, private active: ActivatedRoute) { }

  ngOnInit(): void {
    this.active.params.subscribe(params => {
      this.machineId = parseInt(params['id'])
      this.$machine = this.service.getMachine(this.machineId);
    })
  }

}
