import { RequestFormat } from "../interfaces/request-format";

export class Request {

    public starttime: string;
    public endtime: string;
    public bucket: string;
    public measurement: string;
    public field: string;
    public window?: string;

    constructor(obj: RequestFormat) {
        this.starttime = obj.starttime;
        this.endtime = obj.endtime;
        this.bucket = obj.bucket;
        this.measurement = obj.measurement;
        this.field = obj.field;
        this.window = obj.window;
    }

}