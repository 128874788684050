import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { constants } from 'src/environments/constants';

import { Gantt } from '../../models/classes/gantt';
import { Machine } from '../../models/interfaces/machine';

import { DatepickerService } from '../../services/datepicker.service';
import { HistorianService } from '../../services/historian.service';

@Component({
  selector: 'app-list-of-cip',
  templateUrl: './list-of-cip.component.html',
  styleUrls: ['./list-of-cip.component.scss']
})
export class ListOfCipComponent implements OnInit {

  public range: any = { start: new Date().toISOString(), end: new Date().toISOString() };

  title: string = "SMARTFILTRATION.CIP.TITLE";

  public master?: Gantt;
  public control?: Gantt;
  public cip = constants.stepTypes.cip;
  public $machine?: Observable<Machine>;

  private machineId: number = 0;

  constructor(private active: ActivatedRoute, private router: Router, private service: HistorianService, private datepicker: DatepickerService) {
    this.datepicker.datepickerClosed.subscribe(range => range ? this.fillData() : null);
  }

  ngOnInit(): void {
    this.active.params.subscribe(params => {
      this.machineId = parseInt(params['id'])
      this.$machine = this.service.getMachine(this.machineId);

      this.fillData();
    });
  }

  public onRowClick(row: any): void {
    const query = { typeId: this.machineId, timestart: row.timestart, timeend: row.timeend }
    this.router.navigate(['../cip-details'], { relativeTo: this.active, queryParams: query });
  }

  private fillData(): void {
    this.range = this.datepicker.getRange();

    this.service.getMachineType(this.machineId).subscribe(res => {
      this.service.getSteps(this.service.fillRequest('UP01_Master_Sequence', this.range.start, this.range.end)).subscribe(master => {
        this.master = new Gantt({ id: 1, response: master, machine: res, isCip: true });
      })
      this.service.getSteps(this.service.fillRequest('OP02_CIP_Control', this.range.start, this.range.end)).subscribe(control => {
        this.control = new Gantt({ id: 1, response: control, machine: res, useCipSteps: true });
      })
    });
  }

}
