import { Chart } from "./chart";

export class LineV2 extends Chart {

    private _name: string;
    private _color: string;
    private _type: boolean;
    private _position: any;
    private _min: number;
    private _max: number;
    private _field: string;

    constructor(obj: any) {
        super(obj)
        this._name = obj.name || "line " + obj.id;
        this._color = obj.color ? obj.color : '#0303b8';
        this._type = obj.type || false;
        this._position = obj.position || "left"
        this._min = obj.min || 0;
        this._max = obj.max || 100;
        this._field = obj.field;
    }

    public get name(): string {
        return this._name;
    }

    public set name(v: string) {
        this._name = v;
    }

    public get color(): string {
        return this._color;
    }

    public set color(v: string) {
        this._color = v;
    }

    public get type(): boolean {
        return this._type;
    }

    public set type(v: boolean) {
        this._type = v;
    }

    public get position(): any {
        return this._position;
    }

    public set position(v: any) {
        this._position = v;
    }

    public get min(): number {
        return this._min;
    }

    public set min(v: number) {
        this._min = v;
    }

    public get max(): number {
        return this._max;
    }

    public set max(v: number) {
        this._max = v;
    }

    public get field(): string {
        return this._field;
    }

    public set field(v: string) {
        this._field = v;
    }

    public override toJSON() {
        return {
            id: this.id,
            name: this._name,
            color: this._color,
            type: this._type,
            position: this._position,
            min: this._min,
            max: this._max,
        }
    }

}