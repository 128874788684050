<div class="container">
    <div class="sf-card">
        <span class="title">
            {{ "GENERAL.PROCESS" | translate }} : {{ "SMARTFILTRATION.PRODUCTION.TITLE" | translate
            }}
            ||
            {{ "GENERAL.START" | translate }}: {{date.timestart | proxyDate}}
            -
            {{ "GENERAL.END" | translate }}: {{date.timeend | proxyDate}}
            ||
            {{ "GENERAL.TOTAL" | translate }}
            {{ "GENERAL.TIME" | translate }}: {{date.duration | proxyDate : 'duration'}}
        </span>
    </div>

    <mat-expansion-panel #digitals class="panel" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ "GENERAL.PROCESS" | translate}}
                {{ "SMARTFILTRATION.PRODUCTION.COUNTER" | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="title">
            {{ "SMARTFILTRATION.PRODUCTION.TITLE" | translate}}
            {{ "SMARTFILTRATION.PRODUCTION.VALUE" | translate}}
        </div>
        <div class="expansion-content counter" *ngIf="digitals.expanded">
            <div class="digitals">
                <div class="clock">
                    <app-digital [data]="data['feed']"></app-digital>
                </div>
                <div class="clock">
                    <app-digital [data]="data['electric_power']"></app-digital>
                </div>
            </div>
            <div class="digitals">
                <div class="clock">
                    <app-digital [data]="data['total_permeate']"></app-digital>
                </div>
                <div class="clock">
                    <app-digital [data]="data['peak_power_consumption']"></app-digital>
                </div>
            </div>
            <div class="digitals">
                <div class="clock">
                    <app-digital [data]="data['retentate']"></app-digital>
                </div>
                <div class="clock">
                    <app-digital [data]="data['c02_emission']"></app-digital>
                </div>
            </div>
        </div>
    </mat-expansion-panel>


    <mat-expansion-panel class="panel" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ "SMARTFILTRATION.PRODUCTION.GANTT" | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="gantt-chart">
            <div class="table table-h40">
                <geaui-table-v2 class="process-table" [columnDefinitions]="processGantt" [data]="data['tableMaster']"
                    [loading]="contentReady"></geaui-table-v2>
            </div>
            <div class="gantt">
                <app-gantt [data]="data['master']"></app-gantt>
            </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel #trends class="panel" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ "SMARTFILTRATION.CIP.ANALOG" | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="expansion-content" *ngIf="trends.expanded">
            <div class="skid">
                <app-trend-v2 [data]="data['skid']"></app-trend-v2>
            </div>

            <div class="trend">
                <div class="left">
                    <app-trend-v2 [data]="data['pressure']"></app-trend-v2>
                </div>
                <div class="right">
                    <app-trend-v2 [data]="data['temperature']"></app-trend-v2>
                </div>
            </div>
            <div class="trend">
                <div class="left">
                    <app-trend-v2 [data]="data['flow']"></app-trend-v2>
                </div>
                <div class="right">
                    <app-trend-v2 [data]="data['conductivity']"></app-trend-v2>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</div>