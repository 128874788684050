<div class="content">
    <div class="title bold">
        <span>{{ "GENERAL.NEXT_AUDIT" | translate}}</span>
    </div>
    <a class="contact"
        [href]="'https://www.gea.com/en/contact/index.jsp?p=tcm%3A11-2930-64&f=&q1=service&country=DK&q2=1730'"
        target="_blank" rel="noopener noreferrer">
        <mat-icon aria-hidden="false" aria-label="concat" fontIcon="call" class="icon"></mat-icon>
        <span class="">{{ "GENERAL.CONTACT" | translate}}</span>
    </a>
    <div class="notes" [matTooltip]="notes">
        <mat-icon aria-hidden="false" aria-label="concat" fontIcon="description" class="icon"></mat-icon>
    </div>
    <div class="row">
        <span>{{ "GENERAL.LAST_AUDIT" | translate}}</span>
        <span class="bold">{{lastAudit}}</span>
    </div>
    <div class="row">
        <span>{{ "GENERAL.NEXT_AUDIT" | translate}}</span>
        <span class="bold"> {{nextAudit}}</span>
    </div>
    <div class="row">
        <span>{{ "GENERAL.WEEKS_REMAINING" | translate}}</span>
        <span class="bold">{{weeks | number : '1.0-0'}} {{'GENERAL.WEEKS_REMAINING' | translate}}</span>
    </div>
</div>