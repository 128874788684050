<app-header [title]="title | translate" [machine]="$machine"></app-header>

<div class="resume sf-card">
    <span class="title">{{"SMARTFILTRATION.CIP.LIST_OF" | translate}}</span>

    <div class="table-h40">
        <app-table [data]="master" [filter]="cip" (clickRow)="onRowClick($event)"></app-table>
    </div>
</div>

<mat-expansion-panel class="expansion" [expanded]="false">
    <mat-expansion-panel-header>
        <mat-panel-title>
            {{ "SMARTFILTRATION.CIP.GANTT" | translate}}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row">
        <span class="title">{{"SMARTFILTRATION.CIP.MASTER_CONTROL" | translate}}</span>
        <div class="gantt-master">
            <app-gantt [data]="master"></app-gantt>
        </div>
    </div>
    <div class="row">
        <span class="title">{{"SMARTFILTRATION.CIP.CIP_CONTROL" | translate}}</span>
        <div class="gantt-CIP">
            <app-gantt [data]="control"></app-gantt>
        </div>
    </div>
</mat-expansion-panel>