export const constants = {
    stepTypes: {
        cip: 'CIP',
        production: 'Production'
    },
    colors: [
        '#488f95',
        '#3e464a',
        '#8a963c',
        '#ba2f29',
        '#326fb0',
        '#525252',
        '#c95323',
    ]
}

export enum Groups {
    Status = 1,
    Process = 2,
    WaterText = 3,
    LoopState = 4,
    FlowUnits = 11,
    TemperatureUnits = 12,
    PressureUnits = 13,
    ConductivityUnits = 14,
    TotalizedVolumeUnits = 15,
    PowerUnits = 16,
    CO2Units = 17
}

export const MachineTypes = [
    [5],
    [1, 5]
]

export enum Machines {
    AP,
    PR,
    ALL
}

export enum Documents {
    DataPrivay = 'assets/documents/data_privacy.pdf',
    Eula = 'assets/documents/eula.pdf',
    TermsOfUse = 'assets/documents/terms_of_use_gea.pdf',
    Imprint = 'assets/documents/imprinten-US.pdf'

}