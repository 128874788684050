import { Environment as EnvironmentEnum } from '@gea/digital-ui-lib';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const ip = 'conditional-monitoring-back.azurewebsites.net';

export const environment = {
    name: EnvironmentEnum.TEST,
    production: true,
    apiUrlHistorian: `https://${ip}`,

    clientId: 'fa95e401-2822-478c-819c-ab85df7bc539',
    subscriptionKey: '',
    redirectUri: 'https://smartfiltration.digital.dev.gea.com/',
    postLogoutRedirectUri: 'https://smartfiltration.digital.dev.gea.com/',
    portalUrl: 'https://portal.tst.gea.com/',
    baseUrl: 'https://apim-gea-test.azure-api.net/',
    authority: 'https://login.portal.tst.gea.com/geaidtst.onmicrosoft.com/b2c_1_signin_signup',
    authorityDomain: 'login.portal.tst.gea.com',
    interceptorURLs: ['https://apim-gea-test.azure-api.net/']
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// import 'zone.js/plugins/zone-error';
// Included with Angular CLI.
