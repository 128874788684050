import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MachineTypes, Machines } from 'src/environments/constants';


@Directive({
  selector: '[machineType]'
})
export class MachineTypeDirective implements OnChanges {

  @Input('machineType') machineTypeId?: any;
  @Input() type: Machines = Machines.ALL;

  constructor(private el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    switch (this.type) {
      case Machines.AP:
        if (MachineTypes[Machines.AP].findIndex(f => f == this.machineTypeId) < 0) {
          this.el.nativeElement.style.display = 'none';
        } else {
          this.el.nativeElement.style.display = '';
        }
        break;

      case Machines.PR:
        if (MachineTypes[Machines.PR].findIndex(f => f == this.machineTypeId) < 0) {
          this.el.nativeElement.style.display = 'none';
        } else {
          this.el.nativeElement.style.display = '';
        }
        break;

      default:
        break;
    }
  }

}