import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { constants } from 'src/environments/constants';
import { BarValue } from '../../models/interfaces/bar-value';

import { Historian } from '../../models/interfaces/historian';
import { Machine } from '../../models/interfaces/machine';
import { Step } from '../../models/interfaces/step';
import { Value } from '../../models/interfaces/value';

import { DatepickerService } from '../../services/datepicker.service';
import { HistorianService } from '../../services/historian.service';

@Component({
  selector: 'app-consumption',
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.scss']
})
export class ConsumptionComponent implements OnInit {

  public range: any = { start: new Date().toISOString(), end: new Date().toISOString() };

  title: string = "SMARTFILTRATION.CONSUMPTION.TITLE";

  public prod: Record<string, any> = {};
  public cip: Record<string, any> = {};

  public isHundredProd: boolean = true;
  public isHundredCip: boolean = true;
  public $machine?: Observable<Machine>;

  //Temporary table
  private steps: Record<string, Step> = {};
  private typeCip = constants.stepTypes.cip;
  private typeProduction = constants.stepTypes.production;

  //System constants and fields
  private machineId = 0;
  public machineType = 0;
  private stepReady = 3;
  private fields = ['UP01_Prod_Amount_Counter_AV', 'DC_PowerProd_Total_Act_Power', 'UP01_Batch_DAW_Counter_AV', 'DC_PowerCIP_Total_Act_Power', 'DC_WaterCIP_Total_Flow'];

  constructor(private active: ActivatedRoute, private router: Router, private service: HistorianService, private datepicker: DatepickerService) {
    this.datepicker.datepickerClosed.subscribe(range => range ? this.batchRequest() : null);
  }

  ngOnInit(): void {
    this.active.params.subscribe(params => {
      this.machineId = parseInt(params['id']);
      this.$machine = this.service.getMachine(this.machineId);

      this.service.getMachineType(this.machineId).subscribe(m => {
        m.steps.forEach((s: Step) => this.steps[s.order] = s);
        this.machineType = m.machinetype_id;
        this.batchRequest();
      });
    });
  }

  private batchRequest(): void {
    this.range = this.datepicker.getRange();

    forkJoin({
      table: this.service.getSteps(this.service.fillRequest('UP01_Master_Sequence', this.range.start, this.range.end)),
      fields: this.service.getValues(this.service.fillRequest(this.fields.toString(), this.range.start, this.range.end)),
    }).subscribe(({ table, fields }) => {
      const temp = this.temporaryTable(table);
      this.fillProduction(fields, temp.filter(x => x.type == this.typeProduction));
      this.fillCips(fields, temp.filter(x => x.type == this.typeCip));
    })
  }

  private temporaryTable(data: Value[]): TempTable[] {
    let arr: any[] = [];
    let index = 0;

    while (index < data.length - 1) {
      const value = data[index];
      let type = this.typeProduction;
      let recipe = 'No record';

      index++;

      if (!this.steps[value.step].isready_marker) continue;

      let start = new Date(value.timestart);
      const startIndex = index - 1;
      while (index < data.length - 1 && (data[index].step > this.stepReady)) { index++ }
      let end = new Date(data[index].timestart);

      //Set process type name
      for (let i = index; i >= startIndex; i--) {
        if (this.steps[data[i].step]?.iscip_marker) {
          type = this.typeCip;
          break;
        }
      }

      //Set name for recipe
      if (type == this.typeCip) {
        for (let i = startIndex; i >= 0; i--) {
          if (data[i].cip_recipe != "") {
            recipe = data[i]?.cip_recipe;
            break;
          }
        }
      }
      else if (type == this.typeProduction) {
        for (let i = startIndex; i >= 0; i--) {
          if (data[i].prod_recipe != "") {
            recipe = data[i]?.prod_recipe;
            break;
          }
        }
      }

      let temp = moment.utc(end.getTime() - start.getTime());
      let duration = Number(temp) > 86400000 ? `${Math.floor(moment.duration(end.getTime() - start.getTime()).asDays())}d ` : ''
      duration += Number(temp) > 3600000 ? temp.format('HH[h] mm[m] ss[s]') : temp.format('mm[m] ss[s]');

      arr.push({
        start: startIndex,
        timestart: start.toISOString(),
        timeend: end.toISOString(),
        time: start.toLocaleString(),
        duration: duration,
        recipe: recipe,
        type: type
      });
    }

    arr = arr.slice(0, -1);
    return arr;
  }

  private fillProduction(data: Record<string, Historian[]>, table: TempTable[]) {
    const total = data[this.fields[0]];
    const pwr = data[this.fields[1]];
    const daw = data[this.fields[2]];

    let prodPwrAVG: BarValue[] = [];
    let prodC02AVG: BarValue[] = [];
    let prodTotalPwr: BarValue[] = [];
    let pwrBatchDAW: BarValue[] = [];

    let result = [0, 0, 0];
    let indexs = [0, 0, 0];

    for (let i = 0; i < table.length; i++) {
      let start = new Date(table[i].timestart).getTime();
      let end = new Date(table[i].timeend).getTime();

      for (let j = indexs[0]; j < total?.length; j++) {
        let time = new Date((total[j].time)).getTime()
        if (time > end) {
          indexs[0] = j;
          break;
        }
        if (time > start) {
          result[0] = result[0] < parseFloat(total[j].value) ? parseFloat(total[j].value) : result[0];
        }
      }

      for (let k = indexs[1]; k < pwr?.length; k++) {
        let time = new Date((pwr[k].time)).getTime()
        if (time > end) {
          indexs[1] = k;
          break;
        }
        if (time > start) {
          result[1] = result[1] < parseFloat(pwr[k].value) ? parseFloat(pwr[k].value) : result[1];
        }
      }

      for (let l = indexs[2]; l < daw?.length; l++) {
        let time = new Date((daw[l].time)).getTime()
        if (time > end) {
          indexs[2] = l;
          break;
        }
        if (time > start) result[2] = result[2] < parseFloat(daw[l].value) ? parseFloat(daw[l].value) : result[2];
      }

      prodPwrAVG.push({ time: table[i].timestart, value: (result[1] / result[0]).toString(), recipe: table[i].recipe });
      prodC02AVG.push({ time: table[i].timestart, value: (result[1] * .000287).toString(), recipe: table[i].recipe });
      prodTotalPwr.push({ time: table[i].timestart, value: result[1].toString(), recipe: table[i].recipe });
      pwrBatchDAW.push({ time: table[i].timestart, value: result[2].toString(), recipe: table[i].recipe });

      result = [0, 0, 0];
    }

    this.prod['prodPwrAVGKw'] = prodPwrAVG;
    this.prod['prodPwrAVGW'] = prodPwrAVG.map(m => ({ value: Number(m.value) * 1000, time: m.time, recipe: m.recipe }));
    this.prod['prodC02AVG'] = prodC02AVG;
    this.prod['prodTotalPwr'] = prodTotalPwr;
    this.prod['pwrBatchDAW'] = pwrBatchDAW;
  }

  private fillCips(data: Record<string, Historian[]>, table: TempTable[]) {
    const total = data[this.fields[3]];
    const prod = data[this.fields[4]];

    let cipPwrAVG: BarValue[] = [];
    let cipC02AVG: BarValue[] = [];
    let cipTotalPR: BarValue[] = [];
    let cipWaterFl: BarValue[] = [];

    let result = [0, 0, 0];
    let indexs = [0, 0];

    for (let i = 0; i < table.length; i++) {
      let start = new Date(table[i].timestart).getTime();
      let end = new Date(table[i].timeend).getTime();

      for (let j = indexs[0]; j < total.length; j++) {
        let time = new Date((total[j].time)).getTime()
        if (time > end) {
          indexs[0] = j;
          break;
        }
        if (time > start) {
          result[0] += parseFloat(total[j].value);
          result[2] = result[2] < parseFloat(total[j].value) ? parseFloat(total[j].value) : result[2];
        }
      }

      for (let k = indexs[1]; k < prod.length; k++) {
        let time = new Date((prod[k].time)).getTime()
        if (time > end) {
          indexs[1] = k;
          break;
        }
        if (time > start) result[1] += parseFloat(prod[k].value);
      }

      cipPwrAVG.push({ time: table[i].timestart, value: (result[0] / result[1]).toString(), recipe: table[i].recipe });
      cipC02AVG.push({ time: table[i].timestart, value: (result[0] * 0.832 / result[1]).toString(), recipe: table[i].recipe });
      cipTotalPR.push({ time: table[i].timestart, value: result[2].toString(), recipe: table[i].recipe });
      cipWaterFl.push({ time: table[i].timestart, value: result[1].toString(), recipe: table[i].recipe });

      result = [0, 0, 0];
    }

    this.cip['cipPwrAVGKw'] = cipPwrAVG;
    this.cip['cipPwrAVGW'] = cipPwrAVG.map(m => ({ value: Number(m.value) * 1000, time: m.time, recipe: m.recipe }));
    this.cip['cipC02AVG'] = cipC02AVG;
    this.cip['cipTotalPR'] = cipTotalPR;
    this.cip['cipWaterFl'] = cipWaterFl;
  }

}

export interface TempTable {
  timestart: string,
  timeend: string,
  time: string,
  duration: string,
  recipe: string,
  type: string
}
