<app-header [title]="title | translate" [machine]="$machine"></app-header>

<mat-expansion-panel class="expansion" [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            {{ "SMARTFILTRATION.CONSUMPTION.PRODUCTION" | translate}}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansion-content">
        <div class="barcharts">
            <ng-container *ngIf="isHundredProd; then prodKW else prodW"></ng-container>
            <div class="sf-card bars-right">
                <span class="title">{{ "SMARTFILTRATION.CONSUMPTION.C02_AVG" | translate}}</span>
                <div class="chart">
                    <app-vertical-bar [data]="prod['prodC02AVG']"></app-vertical-bar>
                </div>
            </div>
        </div>
        <div class="barcharts">
            <div class="sf-card bars-left">
                <span class="title">{{ "SMARTFILTRATION.CONSUMPTION.POWER_TOTAL" | translate}}</span>
                <div class="chart">
                    <app-vertical-bar [data]="prod['prodTotalPwr']"></app-vertical-bar>
                </div>
            </div>
            <div class="sf-card bars-right">
                <span class="title" [machineType]="machineType" [type]="1">{{ "SMARTFILTRATION.CONSUMPTION.DIA" |
                    translate}}</span>
                <div class="chart" [machineType]="machineType" [type]="1">
                    <app-vertical-bar [data]="prod['pwrBatchDAW']"></app-vertical-bar>
                </div>
            </div>
        </div>
    </div>
</mat-expansion-panel>

<mat-expansion-panel class="expansion" [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            {{ "SMARTFILTRATION.CONSUMPTION.CIP" | translate}}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansion-content">
        <div class="barcharts">
            <ng-container *ngIf="isHundredCip; then cipKW else cipW"></ng-container>

            <div class="sf-card bars-right">
                <span class="title">{{ "SMARTFILTRATION.CONSUMPTION.CIP_C02_AVG" | translate}}</span>
                <div class="chart">
                    <app-vertical-bar [data]="cip['cipC02AVG']"></app-vertical-bar>
                </div>
            </div>
        </div>
        <div class="barcharts">
            <div class="sf-card bars-left">
                <span class="title">{{ "SMARTFILTRATION.CONSUMPTION.CIP_PWR_TOTAL" | translate}}</span>
                <div class="chart">
                    <app-vertical-bar [data]="cip['cipTotalPR']"></app-vertical-bar>
                </div>
            </div>
            <div class="sf-card bars-right">
                <span class="title">{{ "SMARTFILTRATION.CONSUMPTION.WATER" | translate}}</span>
                <div class="chart">
                    <app-vertical-bar [data]="cip['cipWaterFl']"></app-vertical-bar>
                </div>
            </div>
        </div>
    </div>
</mat-expansion-panel>

<!-- TEMPLATES -->
<ng-template #prodKW>
    <div class="sf-card bars-left">
        <span class="title">{{ "SMARTFILTRATION.CONSUMPTION.POWER_AVG_KW" | translate}}</span>
        <button class="button" type="button" (click)="isHundredProd = !isHundredProd" mat-flat-button>
            Watts
        </button>
        <div class="chart">
            <app-vertical-bar [data]="prod['prodPwrAVGKw']"></app-vertical-bar>
        </div>
    </div>
</ng-template>

<ng-template #prodW>
    <div class="sf-card bars-left">
        <span class="title">{{ "SMARTFILTRATION.CONSUMPTION.POWER_AVG_W" | translate}}</span>
        <button class="button" type="button" (click)="isHundredProd = !isHundredProd" mat-flat-button>
            KW
        </button>
        <div class="chart">
            <app-vertical-bar [data]="prod['prodPwrAVGW']"></app-vertical-bar>
        </div>
    </div>
</ng-template>

<ng-template #cipKW>
    <div class="sf-card bars-left">
        <span class="title">{{ "SMARTFILTRATION.CONSUMPTION.POWER_AVG_KW" | translate}}</span>
        <button class="button" type="button" (click)="isHundredCip = !isHundredCip" mat-flat-button>
            Watts
        </button>
        <div class="chart">
            <app-vertical-bar [data]="cip['cipPwrAVGKw']"></app-vertical-bar>
        </div>
    </div>
</ng-template>

<ng-template #cipW>
    <div class="sf-card bars-left">
        <span class="title">{{ "SMARTFILTRATION.CONSUMPTION.POWER_AVG_W" | translate}}</span>
        <button class="button" type="button" (click)="isHundredCip = !isHundredCip" mat-flat-button>
            KW
        </button>
        <div class="chart">
            <app-vertical-bar [data]="cip['cipPwrAVGW']"></app-vertical-bar>
        </div>
    </div>
</ng-template>