<geaui-page-layout [headerTemplate]="header" [contentTemplate]="content" [sidebarTemplate]="sidebar">
  <ng-template #header>
    <geaui-header #header [noAuth]="noAuth" [showBanner]="false"></geaui-header>
  </ng-template>

  <ng-template #content>
    <div class="container" *ngIf="noAuth || contentReady">
      <router-outlet></router-outlet>
    </div>
  </ng-template>

  <ng-template #sidebar>
    <geaui-sidebar-v2 
    [navigationItems]="navItems"
    [bottomNavigation]="botItems" 
    [devisionName]="'InsightPartner Filtration'">
  </geaui-sidebar-v2>
  </ng-template>
</geaui-page-layout>