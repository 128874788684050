import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../services/global.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Documents } from 'src/environments/constants';

@Component({
  selector: 'app-eula-popup',
  templateUrl: './eula-popup.component.html',
  styleUrls: ['./eula-popup.component.scss']
})
export class EulaPopupComponent {

  public eulaForm: FormGroup = new FormGroup({
    eula: new FormControl(false)
  });

  public errors: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EulaPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalService: GlobalService,
  ) { }

  public openEula(): void {
    window.open(Documents.Eula);
  }

  public openDataPrivacy(): void {
    window.open(Documents.DataPrivay);
  }

  public submit(): void {
    this.errors = !this.eulaForm.controls['eula'].value;

    if (!this.errors) {
      this.globalService.postEula().subscribe(() => window.location.reload());
    } else {
      this.dialogRef.addPanelClass("shake")
      setTimeout(() => this.dialogRef.removePanelClass("shake"), 300);
    }
  }

}
