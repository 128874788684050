import { NgModule } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { NgChartsModule } from 'ng2-charts';
import { ProxyDatePipe } from './pipes/proxy-date.pipe';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { CommonModule } from '@angular/common';

import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { InactivityPopupComponent } from './components/inactivity-popup/inactivity-popup.component';
import { EulaPopupComponent } from './components/eula-popup/eula-popup.component';

Chart.register(zoomPlugin);
Chart.register(ChartDataLabels);

@NgModule({
  declarations: [
    ProxyDatePipe,
    InactivityPopupComponent,
    EulaPopupComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    HttpClientModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    ProxyDatePipe,
    HttpClientModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ]
})
export class SharedModule { }
