import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatepickerService {

  public datepickerClosed = new Subject<boolean>();

  constructor() {
    if (localStorage.getItem("datepicker") === null) {
      localStorage.setItem("datepicker", JSON.stringify({ ...this.getRange() }));
    }
  }

  public getRange(): any {
    const datepicker = localStorage.getItem("datepicker");
    const local = datepicker ? JSON.parse(datepicker) : null;

    if (local) return {
      start: local.start,
      end: local.end,
      selectedRange: local.selectedRange,
      selectedTimeZone: local.selectedTimeZone,
      timeZone: local.timeZone
    };

    return {
      start: moment().startOf('day').toISOString(),
      end: moment().endOf('day').toISOString(),
      selectedRange: 0,
      selectedTimeZone: 0,
      timeZone: undefined
    }

  }

  public setRange(start: string, end: string, selectedRange: number, selectedTimeZone: number): void {
    const datepicker = {
      start: start,
      end: end,
      selectedRange: selectedRange,
      selectedTimeZone: selectedTimeZone,
      timeZone: selectedTimeZone == 1 ? 'UTC' : undefined
    }

    localStorage.setItem("datepicker", JSON.stringify(datepicker));
  }

}
