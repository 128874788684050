import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { HistorianService } from '../../../services/historian.service';

import * as echarts from 'echarts/core';

import { ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent } from 'echarts/components';

import { CustomChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

import { Value } from '../../../models/interfaces/value';
import { Step } from '../../../models/interfaces/step';
import { Gantt } from 'src/app/smartfiltration/models/classes/gantt';
import { CipStep } from 'src/app/smartfiltration/models/interfaces/cip-step';
import { constants } from 'src/environments/constants';
import * as moment from 'moment';
import { DatepickerService } from 'src/app/smartfiltration/services/datepicker.service';

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  CustomChart,
  CanvasRenderer
]);

@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss']
})
export class GanttComponent implements OnChanges {

  @Input() data?: Gantt;
  @ViewChild('chart') chartDOM?: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.chart?.resize()
  }

  private chart: any;
  private steps: Record<string, any> = {};

  private statusProd = constants.stepTypes.production;

  constructor(private service: HistorianService, private dateService: DatepickerService) { }

  ngOnChanges(): void {
    if (!this.data || !this.data?.machine) return;

    if (this.data.useCipSteps)
      this.data.machine.cipsteps.forEach((s: CipStep) => this.steps[s.order] = s);
    else
      this.data.machine.steps.forEach((s: Step) => this.steps[s.order] = s);

    (this.data.response) ? this.fillData(this.data.response) : this.service.getSteps(this.data?.request).subscribe(res => this.fillData(res));
  }

  private fillData(values: Value[]) {
    const { timeZone } = this.dateService.getRange();

    if (!this.data || !this.chartDOM) return;

    let data = values.map(x => [x.step, new Date(x.timestart).getTime(), new Date(x.timeend).getTime(), this.steps[x.step]?.name, this.steps[x.step]?.status_name, !this.steps[x.step]?.iscip_marker]);
    if (data[0][2] - data[0][1] <= 0) data.shift();

    if (this.data.isCip) data = data.filter(x => x[4] != this.statusProd);
    if (this.data.isProd) data = data.filter(x => x[5]);

    //PUT PRODUCTION
    if (data.length <= 0) return;
    const displacement = 200; //Math.max(...data.map(x => String(x[3]).length)) * 8;
    const numRows = new Set(data.map(x => x[0])).size;
    const footerFormat = data[data.length - 1][1] - data[0][1] >= 86400000 ? "{dd}\n{MMM}" : "{HH}:{mm}";

    const options: any = {
      tooltip: {
        formatter: (params: any) => {
          const start = new Date(params.value[1]);
          const end = new Date(params.value[2]);

          let dateStart = start.toLocaleString(undefined, { timeZone: timeZone });
          let dateEnd = end.toLocaleString(undefined, { timeZone: timeZone });

          let temp = moment.utc(end.getTime() - start.getTime());
          let duration = Number(temp) > 86400000 ? `${Math.floor(moment.duration(end.getTime() - start.getTime()).asDays())}d ` : ''
          duration += Number(temp) > 3600000 ? temp.format('HH[h] mm[m] ss[s]') : temp.format('mm[m] ss[s]');

          return `<b>${params.value[3]}</b> <br>Start: ${dateStart} <br>End: ${dateEnd} <br>Duration: ${duration}`;
        }
      },
      animation: false,
      xAxis: {
        type: 'time',
        splitLine: {
          show: true
        },

        axisLabel: {
          fontSize: 14,
          formatter: (value: any, index: any) => {
            return echarts.time.format(value, footerFormat, false);
          }
        }
      },
      yAxis: {
        type: 'category',
        show: true,
        splitLine: {
          show: true
        },
        splitArea: {
          show: true
        },
        axisLabel: {
          inside: false,
          fontSize: 14,
          formatter: (value: any, index: any) => {
            return this.steps[value]?.name || value;
          }
        },
        inverse: true
      },
      grid: {
        show: true,
        height: numRows * 30,
        left: displacement,
        right: '20',
        top: '10',
        bottom: '40'
      },
      series: [
        {
          type: 'custom',
          renderItem: this.renderGanttItem,
          dimensions: ['step', 'timestart', 'timeend', 'name'],
          encode: { x: [1, 2], y: 0, tooltip: [] },
          data: data
        }
      ]
    };

    if (!this.chart) this.chart = echarts.init(this.chartDOM.nativeElement, undefined, { height: (numRows * 30) + 50 })
    this.chart.setOption(options);
  }

  private renderGanttItem(params: any, api: any) {
    let index = api.value(0);

    let start = api.coord([api.value(1), index]);
    let end = api.coord([api.value(2), index]);

    let height = api.size([0, 1])[1] * 0.6;

    let rectShape = echarts.graphic.clipRectByRect({
      x: start[0],
      y: start[1] - height / 2,
      width: end[0] - start[0],
      height: height
    }, {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height
    });

    return rectShape && {
      type: 'rect',
      shape: rectShape,
      style: {
        fill: '#3030B1',
        textPosition: "",
        textDistance: 5,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        fontFamily: "sans-serif",
        textFill: "#000",
        textStroke: "#3030B1",
        textStrokeWidth: 2,
        text: null,
        legacy: true
      }
    };
  }
}