<form [formGroup]="eulaForm" (ngSubmit)="submit()">
    <img class="logo" [src]="'assets/images/GEA_logo_blue.png'">
    <div class="splitter"></div>

    <mat-dialog-content class="mat-typography">
        <p>
            <mat-checkbox formControlName="eula"></mat-checkbox>

            {{'GENERAL.LICENSE.EULA_1' | translate}}
            <a (click)="openEula()" class="link">{{'GENERAL.LICENSE.EULA_LINK' | translate}}</a>
            {{'GENERAL.LICENSE.EULA_2' | translate}}
            <a (click)="openDataPrivacy()" class="link">{{'GENERAL.LICENSE.DATA' | translate}}</a>
        </p>

        <mat-error *ngIf="errors">
            {{'GENERAL.LICENSE.ERROR' | translate}}
        </mat-error>
    </mat-dialog-content>

    <div class="splitter"></div>

    <mat-dialog-actions align="end">
        <button mat-button type="submit">{{'GENERAL.CONTINUE' | translate}}</button>
    </mat-dialog-actions>
</form>