import { Component, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Digital } from '../../../models/classes/digital';
import { HistorianService } from '../../../services/historian.service';
import { Value } from 'src/app/smartfiltration/models/interfaces/value';
import { constants } from 'src/environments/constants';
import { Step } from 'src/app/smartfiltration/models/interfaces/step';
import { zip } from 'rxjs';
import { Request } from 'src/app/smartfiltration/models/classes/request';

@Component({
  selector: 'app-digital-consum',
  templateUrl: './digital-consum.component.html',
  styleUrls: ['./digital-consum.component.scss']
})
export class DigitalConsumComponent {

  @Input() data?: Digital;

  public value: string = '';
  public contentReady: boolean = false;

  private cip = constants.stepTypes.cip;
  private production = constants.stepTypes.production;
  private stepReady: number = 3;

  constructor(private service: HistorianService, public dialog: MatDialog) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.data || !this.data.request) return;

    const request = {
      field: "UP01_Master_Sequence",
      endtime: this.data?.request.endtime,
      starttime: this.data?.request.starttime,
      measurement: this.data?.request.measurement,
      bucket: this.data?.request.bucket
    }

    this.service.getSteps(request).subscribe(res => {
      const arrProcess = this.loadListOfProcess(res);
      const allRequest = arrProcess.map(m => {
        const req: Request = {
          field: this.data!.request.field,
          endtime: m.timeend,
          starttime: m.timestart,
          measurement: this.data!.request.measurement,
          bucket: this.data!.request.bucket
        }

        return this.service.getValues(req);
      });

      let result: number = 0;

      zip(allRequest).subscribe(batched => {
        for (let b in batched) {
          for (let e in batched[b]) {
            result += Math.max(...batched[b][e].map(m => Number(m.value) * (this.data?.adjust || 1))) || 0;
          }
        }

        this.value = result.toLocaleString(undefined, { maximumFractionDigits: this.data?.decimals });
        this.contentReady = true;
      });
    });
  }

  private loadListOfProcess(data: Value[]): Process[] {
    let arr: Process[] = [];
    let index = 0;

    const steps: Record<string, Step> = {};
    this.data?.machine.steps.forEach((s: Step) => steps[s.order] = s);

    while (index < data.length - 1) {
      const value = data[index];
      let type = this.production;

      index++;

      if (!steps[value.step].isready_marker) continue;

      let start = new Date(value.timestart);
      const startIndex = index - 1;
      while (index < data.length - 1 && (data[index].step > this.stepReady)) { index++ }
      let end = new Date(data[index].timestart);

      //Set process type name
      for (let i = index; i >= startIndex; i--) {
        if (steps[data[i].step]?.iscip_marker) {
          type = this.cip;
          break;
        }
      }
      arr.push({
        start: startIndex,
        timestart: start.toISOString(),
        timeend: end.toISOString(),
        type: type
      });
    }

    //Remove first entry if it's not a complete process
    if (data.at(0)?.step == this.stepReady) arr = arr.slice(1);

    //Remove last entry to avoid uncomplete process
    arr = arr.slice(0, -1);

    return arr.reverse();
  }

  public redirect(): void {
    window.open('https://www.eea.europa.eu/ims/greenhouse-gas-emission-intensity-of-1', '_blank');
  }
}

export interface Process {
  start: number,
  timeend: string,
  timestart: string,
  type: string
}