<div class="header">
    <span class="title">{{ title }} -
        <ng-container *ngIf="machine | async as machine">{{ machine.name }}</ng-container>
    </span>
    <button class="time-range" (click)="datePicker = !datePicker">
        <span>{{ range.start | proxyDate : 'timeZone' : range.timeZone }}</span>
        <mat-icon aria-hidden="false" aria-label="Home Icon" fontIcon="arrow_forward"></mat-icon>
        <span>{{ range.end | proxyDate: 'timeZone' : range.timeZone }}</span>
    </button>
    <div class="datepicker" cdkDrag *ngIf="datePicker">
        <app-datepicker (rangeChange)="closeDatePicker($event)"></app-datepicker>
    </div>
</div>