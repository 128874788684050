import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TrendV2 } from '../../models/classes/trend-v2';
import { Chart } from '../../models/classes/chart';
import { Digital } from '../../models/classes/digital';
import { HistorianService } from '../../services/historian.service';
import { constants } from 'src/environments/constants';
import { ColumnDefinition } from '@gea/digital-ui-lib';
import { DatepickerService } from '../../services/datepicker.service';
import { MachineType } from '../../models/interfaces/machine-type';
import { Observable } from 'rxjs';
import { Machine } from '../../models/interfaces/machine';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  public range: any = { start: new Date().toISOString(), end: new Date().toISOString() };
  public contentReady: boolean = true;

  title: string = "SMARTFILTRATION.OVERVIEW.TITLE";

  public data: Record<string, any> = {};
  public $machine?: Observable<Machine>;

  private machineId: number = 0;
  public machineType: number = 0;

  public tableData: any[] = [];
  public cipColumns: ColumnDefinition[] = [
    { displayName: "Time", key: "time" },
    { displayName: "Loop", key: "loop" },
    { displayName: "Temperature", key: "temperature" },
    { displayName: "Pressure", key: "pressure" },
    { displayName: "Flow", key: "flow" },
  ];

  public cipColumnsPr: ColumnDefinition[] = [
    { displayName: "Time", key: "time" },
    { displayName: "Loop", key: "loop" },
    { displayName: "Temperature", key: "temperature" },
    { displayName: "Pressure", key: "pressure" },
    { displayName: "Flow", key: "flow" },
    { displayName: "Flow pr m^2", key: "flow_pflow_pr" }
  ];

  constructor(private active: ActivatedRoute, private router: Router, private service: HistorianService, private datepicker: DatepickerService) {
    this.datepicker.datepickerClosed.subscribe(range => range ? this.getMachineType() : null);
  }

  ngOnInit(): void {
    this.active.params.subscribe(params => {
      this.machineId = parseInt(params['id']);
      this.$machine = this.service.getMachine(this.machineId);

      this.getMachineType();
    });
  }

  //Handle redirections in List of Process's table
  public onRowClick(row: any): void {
    const query = { typeId: this.machineId, timestart: row.timestart, timeend: row.timeend }
    switch (row.type) {
      case constants.stepTypes.production:
        this.router.navigate(['../process-details'], { relativeTo: this.active, queryParams: query });
        break;
      case constants.stepTypes.cip:
        this.router.navigate(['../cip-details'], { relativeTo: this.active, queryParams: query });
        break;
      default:
        break;
    }
  }

  private getMachineType() {
    this.range = this.datepicker.getRange();

    this.service.getMachineType(this.machineId).subscribe(m => {
      this.machineType = m.machinetype_id;

      this.fillData(this.range.start, this.range.end, m);
      this.fillTable(this.range.start, this.range.end, m);
    });
  }

  //Instantiate each Object to fill diferents components with Data (Table is not filled here)
  private fillData(start: string, end: string, machine: MachineType): void {
    let month = new Date(end)
    month.setDate(month.getDate() - 30);
    let week = new Date(end)
    week.setDate(month.getDate() - 7);

    //Fill Charts
    this.data['status'] = new Chart({ id: 1, request: this.service.fillRequest('UP01_Master_Sequence', start, end), machine: machine });

    this.data['table'] = new Chart({ id: 2, request: this.service.fillRequest('UP01_Master_Sequence', start, end), machine: machine });
    this.data['30days'] = new Chart({ id: 3, request: this.service.fillRequest('UP01_Master_Sequence', month.toISOString(), end), machine: machine });
    this.data['07days'] = new Chart({ id: 4, request: this.service.fillRequest('UP01_Master_Sequence', week.toISOString(), end), machine: machine });

    this.data['kwh'] = new Digital({
      id: 5,
      title: 'Electric Power Consume',
      meassure: machine.power_eu_name,
      request: this.service.fillRequest('DC_PowerCIP_Total_Act_Power,DC_PowerProd_Total_Act_Power', start, end),
      machine: machine
    });

    this.data['co2'] = new Digital({
      id: 6,
      title: 'CO2 Emission',
      meassure: machine.co2_eu_name,
      adjust: .0002896,
      decimals: 2,
      altBtn: true,
      request: this.service.fillRequest('DC_PowerCIP_Total_Act_Power,DC_PowerProd_Total_Act_Power', start, end),
      machine: machine
    });

    //Fill Trends
    const matrix = [];

    for (let x = 1; x <= machine.loopcount; x++) {
      matrix[x - 1] = [`L0${x}TT01_SV`, `L0${x}FT01_SV`, `L0${x}PT01_SV`, `L0${x}FT01_pr_m2_SV`,]
    }

    const temperatures = matrix.map(e => e[0]);
    this.data['temperature'] = new TrendV2({
      id: 8,
      title: "Temperature",
      meassure: machine.temperature_eu_name,
      request: this.service.fillRequest(temperatures.toString(), start, end),
      lines: temperatures.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const flows = matrix.map(e => e[1]);
    this.data['flow'] = new TrendV2({
      id: 9,
      title: "Flow",
      meassure: machine.flow_eu_name,
      request: this.service.fillRequest(flows.toString(), start, end),
      lines: flows.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });


    const pressures = matrix.map(e => e[2]);
    this.data['pressure'] = new TrendV2({
      id: 7,
      title: "Pressure",
      meassure: machine.pressure_eu_name,
      request: this.service.fillRequest(pressures.toString(), start, end),
      lines: pressures.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });

    const flowPRs = matrix.map(e => e[3]);
    this.data['flowPR'] = new TrendV2({
      id: 10,
      title: "Flow pr m^2",
      meassure: machine.flow_eu_name,
      request: this.service.fillRequest(flowPRs.toString(), start, end),
      lines: flowPRs.map((e, i) => ({ id: i + 1, name: e, color: constants.colors[i], position: 'none', field: e }))
    });
  }

  //Calculate rows for table in Latest Flux Test Card
  private fillTable(start: string, end: string, machine: MachineType): void {
    const { timeZone } = this.datepicker.getRange();
    const loopInformation = [];

    for (let x = 1; x <= machine.loopcount; x++) {
      loopInformation.push(`L0${x}TT01_SV`, `L0${x}FT01_SV`, `L0${x}PT01_SV`, `L0${x}FT01_pr_m2_SV`)
    }

    this.service.getLastValue(this.service.fillRequest(loopInformation.toString(), start, end)).subscribe(res => {
      let arr: any[] = []

      for (let x = 1; x <= machine.loopcount; x++) {
        arr.push({
          loop: x,
          time: new Date(res[`L0${x}TT01_SV`]?.time).toLocaleString(undefined, { timeZone: timeZone }),
          temperature: this.calcValue(res[`L0${x}TT01_SV`]?.value, machine.temperature_eu_name),
          flow: this.calcValue(res[`L0${x}FT01_SV`]?.value, machine.flow_eu_name),
          pressure: this.calcValue(res[`L0${x}PT01_SV`]?.value, machine.pressure_eu_name),
          flow_pr: this.calcValue(res[`L0${x}FT01_pr_m2_SV`]?.value, machine.flow_eu_name),

        });
      }

      this.tableData = arr;
      this.contentReady = false;
    })
  }

  private calcValue(value: string, unit: string = ''): string {
    if (!+value) return '';
    const res = Number(value) > 100 ? parseInt(value).toLocaleString() : parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: 2 });
    return res + ` ${unit}`;
  }
}