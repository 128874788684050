import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ColumnDefinition } from '@gea/digital-ui-lib';
import { Chart } from '../../../models/classes/chart';
import { Value } from '../../../models/interfaces/value';
import { HistorianService } from '../../../services/historian.service';

import * as moment from 'moment';

import { constants } from 'src/environments/constants';
import { Step } from '../../../models/interfaces/step';
import { DatepickerService } from 'src/app/smartfiltration/services/datepicker.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {

  @ViewChild('table', { read: ElementRef }) table?: any;

  @Input() data?: Chart;
  @Input() filter?: any;

  @Output() clickRow: EventEmitter<any> = new EventEmitter<any>();

  public tableData: any[] = [];
  public columns: ColumnDefinition[] = [];
  public contentReady: boolean = true;

  private steps: Record<string, Step> = {};
  private cip = constants.stepTypes.cip;
  private production = constants.stepTypes.production;

  private stepReady = 3;

  constructor(private service: HistorianService, private dateService: DatepickerService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    this.columns = [
      { displayName: "Process Name", key: "type" },
      { displayName: "Recipe", key: "recipe" },
      { displayName: "Start Time [ID]", key: "time" },
      { displayName: "Total Time", key: "duration" },
    ];

    if (!this.data || !this.data.machine) return;
    this.data?.machine.steps.forEach((s: Step) => this.steps[s.order] = s);

    (this.data.response) ? this.loadData(this.data.response) : this.service.getSteps(this.data.request).subscribe(res => this.loadData(res));
  }

  public onRowClick(rowId: number): void {
    if (!this.tableData) return;
    this.clickRow.emit(this.tableData[rowId]);
  }

  private loadData(data: Value[]) {
    const { timeZone } = this.dateService.getRange();

    let arr: any[] = [];
    let index = 0;

    while (index < data.length - 1) {
      const value = data[index];
      let type = this.production;
      let recipe = 'No record';

      index++;

      if (!this.steps[value.step].isready_marker) continue;

      let start = new Date(value.timestart);
      const startIndex = index - 1;
      while (index < data.length - 1 && (data[index].step > this.stepReady)) { index++ }
      let end = new Date(data[index].timestart);

      //Set process type name
      for (let i = index; i >= startIndex; i--) {
        if (this.steps[data[i].step]?.iscip_marker) {
          type = this.cip;
          break;
        }
      }

      //Set name for recipe
      if (type == this.cip) {
        for (let i = startIndex; i >= 0; i--) {
          if (data[i]?.cip_recipe != "") {
            recipe = data[i].cip_recipe;
            break;
          }
        }
      }
      else if (type == this.production) {
        for (let i = startIndex; i >= 0; i--) {
          if (data[i].prod_recipe != "") {
            recipe = data[i].prod_recipe;
            break;
          }
        }
      }

      let temp = moment.utc(end.getTime() - start.getTime());
      let duration = Number(temp) > 86400000 ? `${Math.floor(moment.duration(end.getTime() - start.getTime()).asDays())}d ` : ''
      duration += Number(temp) > 3600000 ? temp.format('HH[h] mm[m] ss[s]') : temp.format('mm[m] ss[s]');

      arr.push({
        start: startIndex,
        timestart: start.toISOString(),
        timeend: end.toISOString(),
        time: start.toLocaleString(undefined, { timeZone: timeZone }),
        duration: duration,
        recipe: recipe,
        type: type
      });
    }

    //Remove first entry if it's not a complete process
    if (data.at(0)?.step == this.stepReady) arr = arr.slice(1);

    //Remove last entry to avoid uncomplete process
    arr = arr.slice(0, -1);

    if (this.filter) arr = arr.filter(x => x.type == this.filter);

    this.tableData = arr.reverse();
    this.contentReady = false;

    //add pointer to selected row
    let select: HTMLCollection = this.table?.nativeElement.getElementsByTagName('tr');
    setTimeout(() => {
      for (let i = 1; i < select.length; i++) select.item(i)?.addEventListener('click', this.onRowClick.bind(this, (i - 1)))
    }, 100)
  }

}