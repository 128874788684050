<div class="header">
    <span class="title">{{ "SMARTFILTRATION.SERVICE.TITLE" | translate}} -
        <ng-container *ngIf="$machine | async as machine">{{machine.name}}</ng-container>
    </span>
</div>
<div class="indicator">
    <div class="contact">
        <app-card-last-change [load]="true"></app-card-last-change>
    </div>
    <div class="contact">
        <app-card-next-audit [load]="true"></app-card-next-audit>
    </div>
</div>