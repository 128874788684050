import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Chart } from '../../../models/classes/chart';
import { TrendV2 } from '../../../models/classes/trend-v2';

@Component({
  selector: 'app-trend-popup',
  templateUrl: './trend-popup.component.html',
  styleUrls: ['./trend-popup.component.scss']
})
export class TrendPopupComponent implements OnInit {

  public trend?: TrendV2;
  public title: string = '';

  public width: number = 0;
  public height: number = 0;

  constructor(
    public dialogRef: MatDialogRef<TrendPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Chart,
  ) { }

  ngOnInit(): void {
    this.width = (window.innerWidth >= 992 && window.innerHeight >= 440) ? 40 : 80;
    this.height = (window.innerWidth >= 992 && window.innerHeight >= 440) ? 40 : 90;

    this.title = this.data.request.field;
    this.trend = new TrendV2({
      id: 1, request: this.data.request, lines: [
        { id: 1, name: this.data.request.field, color: '#488f95', position: 'none' },
      ]
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  changeSize(): void {
    if (window.innerWidth <= 992 && window.innerHeight <= 440) return;

    this.width = this.width > 40 ? 40 : 80
    this.height = this.height > 40 ? 40 : 80
  }

}
