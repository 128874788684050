<div class="container">
    <div class="top">
        <span class="title"> {{ 'GENERAL.INFORMATION' | translate }} </span>
        <button class="btn-close" (click)="close()">
            <mat-icon class="btn-close-icon" fontIcon="close"></mat-icon>
        </button>
    </div>
    <div class="content">
        <p>{{ 'GENERAL.PROCESS' | translate }} : {{process}}</p>
        <p>{{ 'GENERAL.START' | translate }} : {{ date.timestart | proxyDate }}</p>
        <p>{{ 'GENERAL.END' | translate }} : {{ date.timeend | proxyDate }}</p>

        <button mat-flat-button class="button" (click)="close()">
            {{ 'GENERAL.OK' | translate }}
        </button>
    </div>
</div>