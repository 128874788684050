<div class="container">
    <div class="header">
        <span class="title">{{ "GENERAL.APP_NAME" | translate}} - {{ "GENERAL.LIST" | translate}}</span>
    </div>
    <div class="center sf-card">
        <div class="element" *ngFor="let m of machines" [routerLink]="[m.machine_id, 'overview']">
            <img [src]="'assets/images/SmartFiltration_Icon.png'" [alt]="'FiltrationModule'">
            <div class="card-container">
                <h1>{{m.name}}</h1>
                <p>{{m.machinetype_name}}</p>
            </div>
        </div>
    </div>
</div>